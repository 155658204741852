import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import React, { useState } from 'react';
import Text from '../../../../Shared/Text';
import c from '../../../allocation.module.scss';
import Checkbox from '@mui/material/Checkbox';
import InviteTabs from '../InviteTabs';
import ModalHeaderText from '../../ModalHeaderText';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import {
  InviteDataValidationSchema,
  postInvite,
} from '../../../../../helpers/Allocation';
import { CLIENT_ID } from '../../../../../config/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../../redux/Actions/Loader';
import { googleAnalyticsTriggerFn } from '../../../../../utils/utility';

const InviteByEmail = ({ setStatus, setModalStatus, ...props }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.generalInformation);
  const [tabIndex, setTabIndex] = useState(0);

  const [initialValues, setInitialValues] = useState([
    { fieldsStatus: false, first_name: '', last_name: '', email: '' },
  ]);

  const urlParams = new URLSearchParams(window.location.search);
  const groupId = (urlParams?.size > 0 && urlParams.get('group_id')) || '' ;
  const corpId = (urlParams?.size > 0 && urlParams.get('corp_id')) || '';
  const groupName = (urlParams?.size > 0 && urlParams.get('group_name')) || '';

  let addFormFields = () => {
    setInitialValues([
      ...initialValues,
      { fieldsStatus: false, first_name: '', last_name: '', email: '' },
    ]);
  };

  let handleChange = (i, e) => {
    let newInitialValues = [...initialValues];
    if (e.target.type === 'checkbox') {
      newInitialValues[i][e.target.name] = e.target.checked;
    } else if (e.target.type === 'text' || e.target.type === 'email') {
      newInitialValues[i][e.target.name] = e.target.value;
    }
    // setInitialValues(newInitialValues);
    formik.setFieldValue(newInitialValues);
  };

  const onFormSubmit = async (values) => {
    let email_data = values.filter((item) => item.fieldsStatus);
    email_data = email_data.map((item) => {
      return {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        sender_user_name: userData?.user_name,
      };
    });

    // newValues = {
    //   client_id: userData?.client_id,
    //   platform_id: CLIENT_ID,
    //   email_data: email_data,
    // };
    if (email_data.length === 0) {
      return;
    }

    dispatch(showLoader());
    postInvite(userData?.client_id, email_data, groupId, corpId, groupName);
    dispatch(hideLoader());
    setStatus('EmailSuccess');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: InviteDataValidationSchema,
    onSubmit: (values) => onFormSubmit(values),
  });

  return (
    <React.Fragment>
      <div
        className={classNames('d-flex align-items-end justify-content-between')}
      >
        <div>
          <ModalHeaderText heading="Invite" text="Invite a colleague" />
        </div>
        <Button
          variant="contained"
          className="fw-bold"
          onClick={() => {
            addFormFields();
            googleAnalyticsTriggerFn(
              'Button',
              'Add',
              'Add Button clicked on email invite modal in allocation page'
            );
          }}
        >
          Add
        </Button>
      </div>
      <div className="mt-3">
        <InviteTabs index={tabIndex} setTabIndex={setTabIndex} />
      </div>

      <div className={classNames('col-md-12', c.InviteContainer)}>
        {initialValues.map((element, index) => (
          <div className={classNames('row mt-2')} key={index}>
            <div className="col-md-1 p-0 d-flex align-items-end justify-content-end">
              <Checkbox
                // checked={element.checkboxStatus}
                checked={formik?.values[index]?.fieldsStatus}
                onChange={(e) => handleChange(index, e)}
                inputProps={{ 'aria-label': 'controlled' }}
                name="fieldsStatus"
              />
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className={classNames('col-md-4 mt-3')}>
                  <Text
                    textType="t0"
                    classToOverride={classNames('text-black')}
                  >
                    First Name
                  </Text>
                  <TextField
                    label=""
                    name="first_name"
                    placeholder="Enter First Name"
                    InputLabelProps={{ shrink: false }}
                    error={
                      (formik?.touched[index]?.first_name ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.first_name
                        ? true
                        : false
                    }
                    helperText={
                      (formik?.touched[index]?.first_name ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.first_name
                    }
                    value={formik?.values[index]?.first_name}
                    variant="outlined"
                    className={classNames(c.TextField, 'mt-2')}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className={classNames('col-md-4 mt-3')}>
                  <Text
                    textType="t0"
                    classToOverride={classNames('text-black')}
                  >
                    Last Name
                  </Text>
                  <TextField
                    label=""
                    name="last_name"
                    placeholder="Enter Last Name"
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    className={classNames(c.TextField, 'mt-2')}
                    error={
                      (formik?.touched[index]?.last_name ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.last_name
                        ? true
                        : false
                    }
                    helperText={
                      (formik?.touched[index]?.last_name ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.last_name
                    }
                    value={formik?.values[index]?.last_name}
                    // error={formik?.errors[index]?.last_name ? true : false}
                    // helperText={formik?.errors[index]?.last_name}
                    // value = {formik?.values[index]?.last_name}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className={classNames('col-md-4 mt-3')}>
                  <Text
                    textType="t0"
                    classToOverride={classNames('text-black')}
                  >
                    Email
                  </Text>
                  <TextField
                    label=""
                    name="email"
                    placeholder="Enter Email Address"
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    className={classNames(c.TextField, 'mt-2')}
                    error={
                      (formik?.touched[index]?.email ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.email
                        ? true
                        : false
                    }
                    helperText={
                      (formik?.touched[index]?.email ||
                        formik?.touched[index]?.sumbited) &&
                      formik?.errors[index]?.email
                    }
                    value={formik?.values[index]?.email}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <Button variant="text" onClick={() => formik.handleSubmit(initialValues)}>
        Submit
      </Button> */}
      <div className={classNames(c.ButtonContainer)}>
        <Button
          variant="outlined"
          onClick={() => {
            setModalStatus(false);
            googleAnalyticsTriggerFn(
              'Button',
              'Cancel',
              'Cancel Button clicked on email invite modal in allocation page'
            );
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            formik.handleSubmit(initialValues);
            googleAnalyticsTriggerFn(
              'Button',
              'Invite',
              'Invite Button clicked on email invite modal in allocation page'
            );
          }}
          variant="contained"
          className="ms-2 text-capitalize fw-bold"
          disabled={
            initialValues
              .map((val) => {
                if (val.fieldsStatus) {
                  return true;
                } else {
                  return false;
                }
              })
              .includes(true)
              ? false
              : true
          }
        >
          Invite
        </Button>
      </div>
    </React.Fragment>
  );
};

export default InviteByEmail;

import {
  MIME_TYPE_REGEX,
  UPPERCASE_PATTERN,
  LOWERCASE_PATTERN,
  NUMBER_PATTERN,
  SPECIAL_CHAR_PATTERN,
} from '../../config/Regex/regex';

import store from '../../redux/store';
import { ProfileTabIds } from '../../config/Constants';
import { SET_PROFILE_COMPLETION_STATUS } from '../../redux/Actions/Common';
import { min } from 'lodash';
// import moment from 'moment';
import ReactGA from 'react-ga4';

export const encodeFileToDataURL = (file, setResult) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => setResult(reader.result);
};

export const dataURLtoFile = (dataUrl) => {
  const imgArr = dataUrl?.split(',');
  const mimeType = imgArr[0]?.match(MIME_TYPE_REGEX)[1];
  const decodedStr = Buffer?.from(imgArr[1], 'base64')?.toString('binary');
  let size = decodedStr?.length;
  let u8arr = new Uint8Array(size);
  while (size--) {
    u8arr[size] = decodedStr?.charCodeAt(size);
  }
  return new File([u8arr], 'profileImage', { type: mimeType });
};

export function validatePassword(fieldName) {
  return this.min(8, `${fieldName} must contain minimum 8 characters`)
    .matches(UPPERCASE_PATTERN, `${fieldName} must contain a uppercase letter`)
    .matches(LOWERCASE_PATTERN, `${fieldName} must contain a lowercase letter`)
    .matches(NUMBER_PATTERN, `${fieldName} must contain a number`)
    .matches(
      SPECIAL_CHAR_PATTERN,
      `${fieldName} must contain a special character`
    );
}

export const validateToken = (token) => {
  const decodedPayload = Buffer?.from(token?.split('.')[1], 'base64')?.toString(
    'binary'
  );
  const { exp } = JSON.parse(decodedPayload);
  const isValid = exp * 1000 < Date.now();
  return !isValid;
};

export const calculateProfilePercentage = (profile_details) => {
  const generalInformation = store.getState().generalInformation;
  let percentage = 0;
  let tabsCompleted = [];
  const tabs = Object.keys(ProfileTabIds);
  if (profile_details && profile_details.length > 0) {
    tabsCompleted = [...profile_details.map((item) => item?.tab_id)];
  }
  let tabsNotCompleted = [
    ...tabs.filter((item) => !tabsCompleted.includes(ProfileTabIds[item])),
  ];
  //image percentage
  if (generalInformation?.profile_image_url) {
    percentage += 5;
  }

  //demographics percentage
  const demographicsFields = [
    'first_name',
    'last_name',
    'gender',
    'religion',
    'race',
  ];
  let demographicsFieldsCount = 0;
  demographicsFields.forEach((field) => {
    if (generalInformation[field]) {
      demographicsFieldsCount += 1;
    }
  });
  if (demographicsFields.length - demographicsFieldsCount > 0) {
    tabsNotCompleted.push('demographics');
  } else {
    tabsCompleted.push('demographics');
  }
  percentage += demographicsFieldsCount * 2;

  //final percentage
  //Demographics Tab - 40%, Rest 2 tabs - 30% each totalling 100%
  if (tabsCompleted.includes('demographics')) {
    percentage = 40; //resetting total percentage on completion of the tab (instead of small increments)
  }
  tabsCompleted.filter((item) => {
    if (item != 'demographics') {
      percentage += 30;
    }
  });

  store.dispatch(
    SET_PROFILE_COMPLETION_STATUS({
      incompleteTabs: [...tabsNotCompleted],
      percentage: percentage,
    })
  );
};

export const getTimeInHMS = (duration) => {
  const [hours, minutes, seconds] = duration?.split(':');

  return `${hours}h ${parseInt(minutes)}min ${seconds}s`;
};

// export const areDateEqual = (formattedDate,unFormattedDate) => {
//     const date = moment()
// }

// export const urlShorten = (distraction) => {
//   const initial = distraction.slice(1, -1);
//   const finalurl_after_removing_dot = initial.slice( initial.indexOf(".") + 1, initial.lastIndexOf("/") );
//   return finalurl_after_removing_dot;
// }

export const urlShorten = (distraction) => {
  // e.g 1 - https://chrome.google.com/webstore/
  // e.g 2 - https://www.figma.com/file/
  if (distraction) {
    const url_after_removing_protocol = distraction?.slice(
      distraction?.indexOf('/') + 2
    );
    const url_after_removing_protocol_and_path =
      url_after_removing_protocol?.slice(
        0,
        url_after_removing_protocol?.indexOf('/')
      );
    // works for e.g 1 but for e.g 2 remove "www."
    if (url_after_removing_protocol_and_path?.indexOf('www') > -1) {
      return url_after_removing_protocol_and_path?.slice(4);
    }
    return url_after_removing_protocol_and_path;
  }
};

export const getPercentage = (prev, curr) => {
  if (Number(prev) === 0) {
    return (curr - prev) * 100;
  }
  const per = ((curr - prev) / prev) * 100;
  return per.toPrecision(3);
};

export const getMinutesAndHrsString = (mins = 0) =>
  `${Math.floor(mins / 60)} hrs ${Math.round(mins % 60)} mins`;

export const getMinAndHrString = (mins = 0) => {
  return `${Math.floor(mins / 60)} hr ${Math.round(mins % 60)} min`;
};

export const extractTextFromHTML = (htmlString) => {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(htmlString, 'text/html');
  return parsedDocument.body.textContent || '';
};

// export const getDurationInString = (mins) => {
//   const hours = Math.floor(min / 60);
//   const min =  mins % 60;
//   const sec =
//   return (``)
// }

export const processSessionPages = (pages = []) => {
  const distractedPages = pages?.filter((page) => {
    return !page?.is_relevant_algo && !page?.is_transitional_algo;
  });
  if (pages && pages.length) {
    const relevanceCount = pages.reduce(
      (acc, page) => {
        if (page?.is_relevant_algo) {
          return { ...acc, relevant_count: acc.relevant_count + 1 };
        } else if (page?.is_transitional_algo) {
          return { ...acc, transitional_count: acc.transitional_count + 1 };
        } else return { ...acc, distraction_count: acc.distraction_count + 1 };
      },
      {
        relevant_count: 0,
        transitional_count: 0,
        distraction_count: 0,
      }
    );
    return { distractedPages, relevanceCount };
  }
  return {
    distractedPages,
    relevanceCount: {
      relevant_count: 0,
      transitional_count: 0,
      distraction_count: 0,
    },
  };
};

export const googleAnalyticsTriggerFn = (category, action, label) => {
  ReactGA.event({ category: category, action: action, label: label });
};


export const decimalToTime =(decimalHours)=> {
  // Ensure the input is a valid number
  if (isNaN(decimalHours) || decimalHours < 0) {
    return "Invalid input";
  }
console.log("Parameter", decimalHours)
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(decimalHours);
  const minutesDecimal = (decimalHours - hours) * 60;
  const minutes = Math.floor(minutesDecimal);
  // const seconds = Math.floor((minutesDecimal - minutes) * 60);
  // const result = hours + " hours, " + minutes + " minutes, " + seconds + " seconds";
  
  if (hours===0){
    return minutes + " minutes \n";
  }
  return hours + " hours, " + minutes + " minutes \n";
};


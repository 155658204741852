import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Text from '../../../Shared/Text';
import classNames from 'classnames';
import EmployeeStyling from '../EmployeeStyling.module.scss';
import { useHistory } from 'react-router-dom';
import {
  getMinutesAndHrsString,
  googleAnalyticsTriggerFn,
} from '../../../../utils/utility';
const EmployeeTable = ({
  full_name,
  active,
  department,
  topics,
  topic,
  duration,
  nudges_per_x_min,
  struggling_emotions,
  user_client_id,
}) => {
  // full name user_info.department topics.length topics duration
  // full_name={data?.full_name}
  // department={data?.user_info.department}
  //  topics={data?.topics.length}
  //  topic={data?.topics}
  //  duration={data?.duration}
  const history = useHistory();
  return (
    <>
      <TableRow>
        <TableCell
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
          key={1}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {full_name || ''}
          </Text>
        </TableCell>
        <TableCell
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
          key={1}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {active ? 'Yes':'No'}
          </Text>
        </TableCell>
        <TableCell
          key={4}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {topic ? topic.join(', ') || '-' : '-'}
          </Text>
        </TableCell>
        <TableCell
          key={5}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {getMinutesAndHrsString(duration || 0)}
          </Text>
        </TableCell>
        <TableCell
          key={6}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {nudges_per_x_min || 0}
          </Text>
        </TableCell>
        <TableCell
          key={7}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {struggling_emotions || 'NA'}
          </Text>
        </TableCell>
        <TableCell
          key={8}
          sx={{ cursor: 'pointer' }}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
          onClick={() => {
            history.push(`/employee-details/${user_client_id}`);
            googleAnalyticsTriggerFn(
              'Hyperlink',
              'View Details',
              'View details link clicked on Manager Dashboard'
            );
          }}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            <u>View Details</u>
          </Text>
        </TableCell>
        <TableCell
          key={3}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {topics || 0}
          </Text>
        </TableCell>
        <TableCell
          key={2}
          className={classNames('p-3', EmployeeStyling.columnsStyling)}
        >
          <Text classToOverride={EmployeeStyling.TableData} textType="t1">
            {/* //fixed for now */}
            {/* {'Finance'} */}
            {department || '-'}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeeTable;

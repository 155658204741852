/**
 * @function Targets
 * Component of Profile section
 *
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTargetDetails,
  defaultTargetContent,
  updateTargetDetails,
  generateInitialValues,
  validate,
} from '../../../helpers/Profile/Targets';
import { fetchCategories } from '../../../helpers/LearningPlans/LearningPlansModalBox';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import { useFormik } from 'formik';
import EditAndSaveBtn from '../FormEditAndSubmit';
import _ from 'lodash';
import classNames from 'classnames';
import c from '../Profile.Module.scss';
import Text from '../../Shared/Text/index';
import TargetLearningHours from './TargetLearningHours';
import TargetCapabilities from './TargetCapabilities/index';
import AddTargets from './AddTargets';
// import WishlistCapabilities from './WishlistCapabilities';
import { getProfileDetails } from '../../../helpers/Profile';
import Schedule from './Schedule';
import { fetchLearningPlans } from '../../../helpers/LearningPlans/LearningPlan';

const Targets = () => {
  const [targetData, setTargetData] = useState({});
  const [targetStrengths, setTargetStrengths] = useState([]);
  const [editable, setEditable] = useState(false);
  const [learningPlans, setLearningPlans] = useState([]);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.generalInformation);

  useEffect(() => {
    if (userData?.id) {
      getTargetDetails();
      getTargetStrengths();
      getLearningPlanData();
    }
    // eslint-disable-next-line
  }, [userData]);

  const getTargetDetails = async () => {
    const response = await fetchTargetDetails(userData?.id);
    if (response?.data?.content) {
      setTargetData(response?.data?.content);
    } else {
      setTargetData(defaultTargetContent);
    }
  };

  const getLearningPlanData = async () => {
    const response = await fetchLearningPlans();
    if (response?.data) {
      setLearningPlans(response?.data);
    } else {
      setLearningPlans([]);
    }
  };

  const getTargetStrengths = async () => {
    const response = await fetchCategories();
    if (response?.data) {
      setTargetStrengths(response?.data);
    }
  };

  const onFormSubmit = async (values) => {
    dispatch(showLoader());
    await updateTargetDetails(values);
    getTargetDetails();
    await getProfileDetails(userData?.id);
    dispatch(hideLoader());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues(targetData),
    validate,
    onSubmit: (values) => onFormSubmit(values),
  });

  return (
    <>
      <EditAndSaveBtn
        editable={editable}
        setEditable={setEditable}
        handleSubmit={formik?.handleSubmit}
        disabled={
          _.isEmpty(targetData) ||
          !formik?.isValid ||
          Object.values(formik?.values).includes(null)
        }
        page="Targets"
      />
      <div className={classNames(c.targets, 'pt-3 ps-3 rounded mt-5')}>
        <Text textType="t1" text="Targets" classToOverride={c.fontWeight600} />
        {/* <Text textType="t2" classToOverride={classNames(c.fade, 'fw-normal')}>
          Another line here for description
        </Text> */}
        <div className="row py-4">
          <div className="col-6">
            <TargetLearningHours
              // targetWeekHrs={targetData?.targetHoursAWeek}
              // targetMonthHrs={targetData?.targetHoursAMonth}
              data={targetData}
            />
          </div>
          <div className="col-6 pe-5">
            <TargetCapabilities
              strengths={targetData?.targetCapabilities}
              capabilityList={targetStrengths}
            />
          </div>
        </div>
        <div className="py-5">
          {editable && (
            <AddTargets formik={formik} capabilities={targetStrengths} />
          )}
        </div>
      </div>
      <div className="row g-0 mt-5">
        <Schedule
          capabilities={targetStrengths}
          learningPlans={learningPlans}
        />
      </div>
    </>
  );
};
export default Targets;

import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';
import c from '../allocation.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';
import {
  getMinutesAndHrsString,
  googleAnalyticsTriggerFn,
} from '../../../utils/utility';
import {
  setNeighborhoodAdmin,
  updateSaveVideo,
  updateIsTeacher,
  updateIsAssign,
} from '../../../helpers/Allocation/AllocationTable';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_TYPE, CLIENT_ID } from '../../../config/Constants';
import RequestMembersContainer from '../RequestMembersContainer';
import RequestMembersModal from '../RequestMembersModal';

const AllocationTable = ({
  name,
  department,
  studyHours,
  sessions,
  topics,
  viewDashboard,
  assign,
  isAdmin,
  isTeacher,
  saveVideo,
  ext_id,
  user_id,
  user_client_id,
  getAllocationTableData,
  groupId,
  corpId,
  isMentor,
  selectedIds,
  handleCheckboxChange,
  isCheckbox,
  groupStatus,
  isMember,
  id,
  setSelectedIds,
}) => {
  const [actionValue, setActionValue] = useState('actions');

  const [assignBoolean, setAssignBoolean] = useState(false);
  const [isAdminBoolean, setIsAdminBoolean] = useState(false);
  const [isTeacherBoolean, setIsTeacherBoolean] = useState(false);
  const [isMentorBoolean, setIsMentorBoolean] = useState(false);
  const [saveVideoBoolean, setSaveVideoBoolean] = useState(false);
  const [memberStatus, setMemberStatus] = useState(false);
  const [isRequestApprove, setIsRequestApprove] = useState(false);
  const [isAcceptedMember, setIsAcceptedMember] = useState(false);
  const client_id = useSelector(
    (state) => state?.generalInformation?.client_id
  );

  const currentUserId = useSelector(
    (state) => state?.generalInformation?.user_id
  );

  const history = useHistory();

  useEffect(() => {
    assign && setAssignBoolean(assign);
    isAdmin && setIsAdminBoolean(isAdmin);
    isTeacher && setIsTeacherBoolean(isTeacher);
    saveVideo && setSaveVideoBoolean(saveVideo);
  }, [assign, isAdmin, isTeacher, saveVideo]);

  useEffect(() => {
    if (groupId && corpId) {
      setIsMentorBoolean(isMentor);
    }
  }, [groupId, corpId, isMentor]);

  useEffect(() => {
    if (groupId && corpId) {
      setMemberStatus(isMember);
    }
  }, [groupId, corpId, isMember]);



  const handleIsAdmin = async (isAdmin, type = 'manager') => {
    const params = {
      client_id: type === 'mentor' ? groupId : client_id,
      platform_id: CLIENT_ID,
      user_id: user_id,
      is_admin: isAdmin,
    };
    const response = await setNeighborhoodAdmin(params);
    if (response) {
      if (type === 'mentor') {
        setIsMentorBoolean(isAdmin);
      } else {
        setIsAdminBoolean(isAdmin);
      }
    }
    console.log('Responce', response);
    getAllocationTableData();
  };
  const handleSaveVideo = async (isSave) => {
    // console.log('Save', isSave);
    setSaveVideoBoolean(isSave);
    const params = {
      ext_id: ext_id,
      recording_flag: isSave,
      corp_id: client_id,
    };
    const response = await updateSaveVideo(params);
    // console.log('Responce', response);
    getAllocationTableData();
    // };
  };

  const handleIsTeacher = async (isTeacher) => {
    setIsTeacherBoolean(isTeacher);
    const params = {
      id: user_client_id,
      category_id: isTeacher ? ACCOUNT_TYPE.TEACHER : null,
    };
    const response = await updateIsTeacher(params);
    // console.log('Responce', response);
    getAllocationTableData();
    // };
  };
  
  const handleIsAssign = async (isAssign) => {
    setAssignBoolean(isAssign);
    const params = {
      user_id: user_id,
      platform_id: CLIENT_ID, //Ahura Client ID
      client_id: client_id,
      is_active: isAssign,
    };
    const response = await updateIsAssign(params);
    // console.log('Responce', response);
    getAllocationTableData();
    // };
  };

  return (
    <>
      <TableRow
        className={classNames(
          c.allocationTableRow,
          groupStatus === 'PENDING' && corpId && groupId && c.pendingRequestsRow
        )}
      >
        {isCheckbox && (
          <>
            {groupStatus === 'PENDING' ? (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedIds.includes(id)}
                  onChange={(e) => {
                    handleCheckboxChange(e, id);
                  }}
                />
              </TableCell>
            ) : (
              <TableCell />
            )}
          </>
        )}

        <TableCell>{name || '-'}</TableCell>
        {/* <TableCell>{department || '-'}</TableCell> */}
        <TableCell>{getMinutesAndHrsString(studyHours) || 0}</TableCell>
        <TableCell>{sessions || '-'}</TableCell>
        <TableCell>{topics?.length > 0 ? topics.join(', ') : '-'}</TableCell>
        {/* <TableCell sx={{ width: 160 }}>Soon</TableCell> */}
        <TableCell sx={{ cursor: 'pointer' }}>
          <div
            className={classNames('c.cursorPointer')}
            onClick={() => {
              history.push(`/employee-details/${user_client_id}`);
              googleAnalyticsTriggerFn(
                'Hyperlink',
                'View Details',
                'View details link clicked on allocation page'
              );
            }}
          >
            <u>View Details</u>
          </div>
        </TableCell>
        <TableCell>
          <Select
            fullWidth
            className={classNames(
              'text-white rounded',
              c.font_14,
              c.allocationActionsMenu
            )}
            variant="standard"
            value={actionValue}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{
              className: classNames('bg-transparent p-2'),
              classes: {
                icon: classNames('text-white me-1'),
              },
            }}
            disableUnderline
          >
            <MenuItem value="actions" hidden>
              Actions
            </MenuItem>
            {/* {options.map(({ value, label }, index) => {
      return (
        <MenuItem key={index}>
          <FormControlLabel
            control={<Checkbox size="small" />}
            value={value}
            label={label}
            checked={checkedState[index]}
            onChange={() => handleOnChange(index)}
          />
        </MenuItem>
      );
    })} */}

            {!(groupId && corpId) && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value={assignBoolean}
                  label="Assign"
                  checked={assignBoolean ? assignBoolean : false}
                  onChange={(e) => {
                    handleIsAssign(e.target.checked);
                    googleAnalyticsTriggerFn(
                      'Checkbox',
                      'Assign',
                      'Assign checkbox clicked under Actions Menu in Allocation table'
                    );
                  }}
                />
              </MenuItem>
            )}
            {!(groupId && corpId) && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value={isAdminBoolean}
                  label="Manager"
                  checked={isAdminBoolean ? isAdminBoolean : false}
                  onChange={(e) => {
                    // console.log('Admin', e.target.checked);
                    handleIsAdmin(e.target.checked);
                    googleAnalyticsTriggerFn(
                      'Checkbox',
                      'Manager',
                      'Manager checkbox clicked under Actions Menu in Allocation table'
                    );
                  }}
                />
              </MenuItem>
            )}
            {groupId && corpId && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value={isMentorBoolean}
                  label="Mentor"
                  checked={isMentorBoolean ? isMentorBoolean : false}
                  onChange={(e) => {
                    handleIsAdmin(e.target.checked, 'mentor');
                  }}
                  disabled={groupId && corpId && currentUserId === user_id}
                />
              </MenuItem>
            )}

            {/* for approve members checkbox  */}
            {groupId && corpId && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value={groupStatus === 'PENDING' ? isRequestApprove : memberStatus}
                  label="Approve Members"
                  checked={groupStatus === 'PENDING' ? isRequestApprove : memberStatus}
                  onChange={() => {
                    if (groupStatus === 'PENDING') {
                      setIsRequestApprove(true);
                    } else {
                      setIsAcceptedMember(true);
                    }
                  }}
                  disabled={groupStatus === 'PENDING' ? selectedIds?.length === 0 : groupId && corpId && currentUserId === user_id}
                />
              </MenuItem>
            )}
            {!(groupId && corpId) && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value={isTeacherBoolean}
                  label="Teacher"
                  checked={isTeacherBoolean ? isTeacherBoolean : false}
                  onChange={(e) => {
                    handleIsTeacher(e.target.checked);
                    googleAnalyticsTriggerFn(
                      'Checkbox',
                      'Teacher',
                      'Teacher checkbox clicked under Actions Menu in Allocation table'
                    );
                  }}
                />
              </MenuItem>
            )}
            {!(groupId && corpId) && (
              <MenuItem>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value="saveVideo"
                  label="Train with video"
                  checked={
                    ext_id === null || sessions === undefined
                      ? true
                      : saveVideoBoolean
                      ? saveVideoBoolean
                      : false
                  }
                  onChange={(e) => {
                    handleSaveVideo(e.target.checked);
                    googleAnalyticsTriggerFn(
                      'Checkbox',
                      'Train with video',
                      'Train with video checkbox clicked under Actions Menu in Allocation table'
                    );
                  }}
                  disabled={
                    ![true, false].includes(saveVideo) || ext_id === null
                  }
                />
              </MenuItem>
            )}
          </Select>
        </TableCell>
      </TableRow>
      <RequestMembersContainer
        requestModalStatus={groupStatus === 'PENDING' ? isRequestApprove : isAcceptedMember}
        setRequestModalStatus={groupStatus === 'PENDING' ? setIsRequestApprove : setIsAcceptedMember}
      >
        <RequestMembersModal
          selectedMembers={selectedIds}
          setRequestModalStatus={groupStatus === 'PENDING' ? setIsRequestApprove : setIsAcceptedMember}
          getAllocationTableData={getAllocationTableData}
          groupStatus={groupStatus}
          memberStatus={memberStatus}
          setMemberStatus={setMemberStatus}
          user_id={user_id}
          groupId={groupId}
          name={name}
          setSelectedIds={setSelectedIds}
        />
      </RequestMembersContainer>
    </>
  );
};

export default AllocationTable;

import { getApiCall, putApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
import { CLIENT_ID, ProfileTabIds } from '../../../config/Constants';
import store from '../../../redux/store';

export const ChartDataProfileTargets = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  datasets: [
    {
      type: 'bar',
      label: 'Capabilities Studied',
      data: [25, 95, 33, 72, 97, 34, 23, 22, 53, 66],
      borderColor: 'rgb(255, 99, 132)',
      borderRadius: 3,
      backgroundColor: '#87D4E4',
    },
  ],
};

export const ChartOptionProfileTargets = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      max: 100,
      display: false,
      ticks: {
        min: 25,
        max: 100,
        stepSize: 25,
        color: '#4318FF',
      },
      grid: {
        display: true,
      },
    },
    x: {
      display: false,
      ticks: {
        color: '#A3AED0',
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const defaultTargetContent = {
  targetNumber: 0,
  targetHoursAWeek: 0,
  targetHoursAMonth: 0,
  targetCapabilities: {
    1: 'Strength #1',
    2: 'Strength #2',
    3: 'Strength #3',
  },
};

export const targetLearningHoursForm = [
  {
    id: 'targetNumber',
    label: 'Target Capabilities per week',
    placeholder: 'Enter Target Number',
  },
  {
    id: 'targetHoursAWeek',
    label: 'Target Hours a Week',
    placeholder: 'Enter Target Hours',
  },
  {
    id: 'targetHoursAMonth',
    label: 'Target hours a Month',
    placeholder: 'Enter Target Month',
  },
];

export const targetCapabilitiesForm = [
  { id: 'firstCapability', label: 'Assign Target', placeholder: 'Type Name' },
  { id: 'secondCapability', label: 'Assign Target', placeholder: 'Type Name' },
  { id: 'thirdCapability', label: 'Assign Target', placeholder: 'Type Name' },
];

export const generateInitialValues = (targetData) => {
  let values = {};
  values.targetNumber = targetData?.targetNumber || '';
  values.targetHoursAWeek = targetData?.targetHoursAWeek || '';
  values.targetHoursAMonth = targetData?.targetHoursAMonth || '';

  if (targetData?.targetCapabilities) {
    const capabilitiesInfo = targetData?.targetCapabilities;
    values.firstCapability = capabilitiesInfo ? capabilitiesInfo[1] : '';
    values.secondCapability = capabilitiesInfo ? capabilitiesInfo[2] : '';
    values.thirdCapability = capabilitiesInfo ? capabilitiesInfo[3] : '';
  }

  return values;
};

export const validate = (values) => {
  let errors = {};
  if (values?.targetNumber === '') {
    errors.targetNumber = 'Target Number is required';
  }
  if (values?.targetHoursAWeek === '') {
    errors.targetHoursAWeek = 'Target Hours a Week is required';
  }
  if (values?.targetHoursAMonth === '') {
    errors.targetHoursAMonth = 'Target Hours a Month is required';
  }
  if (values?.firstCapability === '') {
    errors.firstCapability = 'Capability is required';
  }
  if (values?.secondCapability === '') {
    errors.secondCapability = 'Capability is required';
  }
  if (values?.thirdCapability === '') {
    errors.thirdCapability = 'Capability is required';
  }
  return errors;
};

export const updateTargetDetails = async (values) => {
  const { id } = store?.getState()?.generalInformation;
  try {
    let reqBody = {
      user_client_id: id,
      tab_id: ProfileTabIds?.targets,
      platform_id: CLIENT_ID,
      content: {
        targetNumber: values?.targetNumber,
        targetHoursAWeek: values?.targetHoursAWeek,
        targetHoursAMonth: values?.targetHoursAMonth,
        targetCapabilities: {
          1: values?.firstCapability,
          2: values?.secondCapability,
          3: values?.thirdCapability,
        },
      },
    };

    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    await putApiCall(API_URLS?.profile_details, reqBody, paramsData);
  } catch (error) {
    console.log(`Error in updating Target Details ${error}`);
  }
};

export const fetchTargetDetails = async (user_client_id) => {
  try {
    const paramsData = {
      queryParams: {
        user_client_id: user_client_id,
        tab_id: ProfileTabIds?.targets,
        platform_id: CLIENT_ID,
      },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URLS?.profile_details, paramsData);
    return response;
  } catch (error) {
    console.log(`Error in fetching Target Details ${error}`);
  }
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Quiz from '../../components/Course/Quiz';
import { getQuizByLesson, getAssignmentQuestions } from '../../helpers/Quiz';

const QuizPage = () => {
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [quizQues, setQuizQues] = useState([]);
  const [quizData, setQuizData] = useState({});
  const [assignment_id, setAssignmnetId] = useState('');
  const [course_id, setCourseId] = useState('');
  useEffect(async () => {
    if (params?.lesson_id) {
      setLoader(true);
      const res = await getQuizByLesson(params?.lesson_id);
      setAssignmnetId(res[0]?.id);
      setCourseId(res[0]?.parent_id);
      const data = await getAssignmentQuestions(res[0]?.id);
      console.log('res::::', data);
      setQuizData({ ...res[0] });
      setQuizQues(data);
      setLoader(false);
    }
  }, [params]);
  return (
    <Quiz
      loader={loader}
      quizQues={quizQues}
      quizData={quizData}
      lesson_id={params?.lesson_id}
      assignment_id={assignment_id}
      course_id={course_id}
    />
  );
};

export default QuizPage;

import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall, putApiCall } from '../../../utils/axios/axios';

export const columns = [
  { id: 'name', label: 'Name' },
  // { id: 'department', label: 'Department' },
  { id: 'Study_hours', label: 'Study hours' },
  { id: 'sessions', label: 'Sessions' },
  { id: 'topics', label: 'Topics' },
  // { id: 'license_expiry', label: 'License Expiry' },
  { id: 'view_dashboard', label: 'View Dashboard' },
  { id: 'actions', label: 'Actions' },
];

export const fetchAllocationTable = async (params) => {
  try {
    let memberPayload = {};
    if (params?.is_group) {
      memberPayload.is_group = true
    }
    const paramsData = {
      queryParams: {
        corporate_id: params?.corporate_id,
        platform_id: params?.platform_id,
        limit: params?.limit,
        search: params?.search,
        page_no: params?.page_no,
        ...memberPayload,
      },
      headers: {},
      isAuthorized: true,
    };
    const res = await getApiCall(API_URLS.allocation_table, paramsData);
    return res;
  } catch (err) {
    console.log('Error in fetchAllocationTable | Helpers :', err);
  }
};

export const setNeighborhoodAdmin = async (params) => {
  try {
    const reqBody = {
      platform_id: params?.platform_id,
      client_id: params?.client_id,
      user_id: params?.user_id,
      is_admin: params?.is_admin,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await putApiCall(
      API_URLS.set_neighborhood_admin,
      reqBody,
      header
    );
    return res;
  } catch (err) {
    console.log('Error in set Neighborhood Admin | Helpers :', err);
  }
};

export const updateSaveVideo = async (params) => {
  try {
    const reqBody = {
      ext_id: params?.ext_id,
      recording_flag: params?.recording_flag,
      corp_id: params?.corp_id,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await putApiCall(API_URLS.update_save_video, reqBody, header);
    return res;
  } catch (err) {
    console.log('Error in saving Video | Helpers :', err);
  }
};

export const updateIsTeacher = async (params) => {
  try {
    const reqBody = {
      id: params?.id,
      category_id: params?.category_id,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await putApiCall(API_URLS.users, reqBody, header);
    return res;
  } catch (err) {
    console.log('Error in is teacher | Helpers :', err);
  }
};

export const updateIsAssign = async (params) => {
  try {
    const reqBody = {
      user_id: params?.user_id,
      platform_id: params?.platform_id,
      client_id: params?.client_id,
      is_active: params?.is_active,
    };
    const header = { queryParams: {}, headers: {}, isAuthorized: true };
    const res = await putApiCall(
      API_URLS.updateNeighborhoodActiveStatus,
      reqBody,
      header
    );
    return res;
  } catch (err) {
    console.log('Error in isAssign | Helpers :', err);
  }
};

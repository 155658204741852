import { CLIENT_ID } from '../../../config/Constants';
import {
  getApiCall,
  postApiCall,
  putApiCall,
} from '../../../utils/axios/axios';
import axiosInstance from '../../../utils/axios/axiosInstance';
import { API_URLS } from '../../config/API_URLS';

export const getNetworkSummary = async (param = {}) => {
  try {
    const paramsData = {
      queryParams: {
        client_id: CLIENT_ID,
        ...param,
      },
      headers: {},
      isAuthorized: true,
    };
    const data = await getApiCall(API_URLS?.getNetworkSummary, paramsData);
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getNetworkUsers = async (param = {}) => {
  try {
    const params = {
      ...param,
      client_id: CLIENT_ID,
    };

    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };

    const data = await getApiCall(API_URLS.getNetworkUsers, paramsData);
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

//activate_or_deactivate
export const activeOrdeactive = async (reqBody) => {
  try {
    const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
    const data = await postApiCall(
      API_URLS.activate_or_deactivate,
      reqBody,
      paramsData
    );
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getPendingRequests = async () => {
  try {
    const data = await axiosInstance.get(API_URLS.pendingRequests);
    return data?.data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const approveDocuments = async (reqBody) => {
  try {
    const response = await axiosInstance.post(
      API_URLS.approveDocuments,
      reqBody
    );
    return response;
  } catch ({ request }) {
    throw new Error(request?.data?.error?.message);
  }
};

export const getDocumentData = async (owner_id) => {
  try {
    const response = await axiosInstance.get(API_URLS.getDocument, {
      params: { owner_id: owner_id },
    });
    return response;
  } catch ({ request }) {
    throw new Error(request?.data?.error?.message);
  }
};

// export const addCategories = async (values) => {
//   try {
//     const reqBody = {
//       name: values.category,
//       color_code: '',
//       created_by: values.id,
//       updated_by: values.id,
//       is_active: true,
//       is_global: false,
//       client_id: CLIENT_ID,
//       owner_id: values.id,
//       crm_module_id: CRM_MODULES_ID.NETWORKS,
//       name_for_shops: values.category,
//       parent_id: values.parent_id,
//     };

//     const data = await axiosInstance.post(API_URLS.addCategories, reqBody);
//     return data;
//   } catch ({ response }) {
//     throw new Error(response?.data?.error?.message);
//   }
// };

export const addSchool = async (values) => {
  try {
    const reqBody = {
      client_id: CLIENT_ID,
      is_active: true,
      name: values.school_name,
    };
    await axiosInstance.post(API_URLS.addSchool, reqBody);
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getSchoolList = async () => {
  try {
    const params = {
      platform_id: CLIENT_ID,
    };
    const data = await axiosInstance.get(API_URLS.addSchool, { params });
    return data.data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getEstates = async ({ sportId, schoolId }) => {
  try {
    const params = {
      sport_id: sportId,
      school_id: schoolId,
    };
    const data = await axiosInstance.get(API_URLS.getEstates, { params });
    return data.response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getEstateInfo = async (param) => {
  try {
    const params = {
      ...param,
      estate_id: param.estate_id,
    };
    const data = await axiosInstance.get(API_URLS.getEstateInfo, { params });
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const updateIsAdmin = async (values) => {
  try {
    const reqBody = {
      ...values,
      client_id: CLIENT_ID,
    };
    await putApiCall(API_URLS.updateIsAdmin, reqBody);
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const updateUserStatus = async (values) => {
  try {
    const reqBody = {
      ...values,
    };
    await axiosInstance.post(API_URLS.activate_or_deactivate, reqBody);
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getNeighborhoods = async (params = {}) => {
  try {
    const data = await getApiCall(API_URLS.getNeighborhoods, {
      queryParams: {
        ...params,
        client_id: CLIENT_ID,
      },
      isAuthorized: true,
    });
    return data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const getNeighborhoodMemebers = async (id) => {
  try {
    const data = await getApiCall(API_URLS.getNeighborhoodMemers, {
      queryParams: {
        platform_id: CLIENT_ID,
        corporate_id: id,
      },
      isAuthorized: true,
    });
    return data.data;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const setNeighborhoodadmin = async (isAdmin, userId, clientId) => {
  try {
    const data = await axiosInstance.put(
      API_URLS.setNeighborhoodadmin,
      {
        platform_id: CLIENT_ID,
        client_id: clientId,
        is_admin: isAdmin,
        user_id: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    return;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

export const removeFromNeighborhood = async (
  userId,
  clientId,
  externalId = null
) => {
  try {
    await axiosInstance.put(
      API_URLS.removeUserFromNeighborhood,
      {
        client_id: clientId,
        external_id: externalId,
        platform_id: CLIENT_ID,
        user_id: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    return;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};

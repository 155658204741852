import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fetchTargetDetails } from '../../../helpers/Profile/Targets';
import { fetchCategories } from '../../../helpers/LearningPlans/LearningPlansModalBox';
import Headings from '../../Shared/Headings';
import classNames from 'classnames';
import c from '../home.Module.scss';
import CapabilitiesToStudyTabs from './CapabilitiesToStudyTabs';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CapabilityCard from './CapabilityCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Text from '../../Shared/Text';
import Skeleton from '@mui/material/Skeleton';
import NoCapabilitiesAssignedUI from './NoCapabilitiesAssignedUI';
import { Button } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AssignLearningPlan from './AssignLearningPlan';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';

const CapabilitiesToStudy = ({ showAddLearningPlanBtn = false }) => {
  const [tabIndex, setTabIndex] = useState(showAddLearningPlanBtn ? 1 : 0);
  const [showModal, setShowModal] = useState(false);
  const [capabilitiesList, setCapabilitiesList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const userData = useSelector((state) => state?.generalInformation);

  useEffect(() => {
    if (userData?.id) {
      getTargetCapabilities();
    }
    // eslint-disable-next-line
  }, [userData]);

  const getTargetCapabilities = async () => {
    const { data } = await fetchTargetDetails(userData?.id);
    if (data?.content?.targetCapabilities) {
      const { targetCapabilities } = data?.content;
      if (targetCapabilities) {
        const capabilityIds = Object.values(targetCapabilities);
        const { data } = await fetchCategories();
        if (data) {
          const capabilitiesInfo = data?.filter((item) =>
            capabilityIds.includes(item?.id)
          );
          setCapabilitiesList(capabilitiesInfo);
        }
      }
    }
    setShowLoader(false);
  };

  const markComplete = (id) => {
    const newList = capabilitiesList?.filter((item) => item?.id !== id);
    setCapabilitiesList(newList);
  };

  const swiper = useRef(null);

  return (
    <>
      {showModal && showAddLearningPlanBtn && (
        <AssignLearningPlan showModal={showModal} setShowModal={setShowModal} />
      )}
      <div className="h-100 w-100 bg-white mt-5 px-5 py-4">
        <div className="row justify-content-between align-items-center">
          <Headings
            headingType="h3"
            classToOverride={classNames(' col-4', c.fontWeight600)}
          >
            Capabilities to Study
          </Headings>
          {showAddLearningPlanBtn && (
            <div className="col-3 text-end">
              <Button
                variant="contained"
                startIcon={<CreateNewFolderIcon />}
                sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  setShowModal(true);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Assign Learning Plan',
                    'Assign Learning Plan button clicked'
                  );
                }}
              >
                Assign Learning Plan
              </Button>
            </div>
          )}
        </div>
        <div className="row g-0 ps-5 pe-2">
          <CapabilitiesToStudyTabs
            index={tabIndex}
            setTabIndex={setTabIndex}
            isEmployeeDetailPage={showAddLearningPlanBtn}
          />
        </div>
        {!showAddLearningPlanBtn &&
          tabIndex === 0 &&
          (capabilitiesList.length > 0 ? (
            <>
              <div className="pt-4 d-flex align-items-center">
                {capabilitiesList?.length > 2 && (
                  <ArrowBackIosNewIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft -= 500)}
                  />
                )}
                <div
                  className={classNames(
                    'd-flex justify-content-between',
                    c.slider,
                    capabilitiesList?.length < 3 && 'ms-5'
                  )}
                  ref={swiper}
                >
                  {capabilitiesList.map((item, index) => (
                    <CapabilityCard
                      key={item?.id}
                      index={index + 1 - 5 * parseInt(index / 5)}
                      cardId={item?.id}
                      cardHead={item?.name}
                      handleClick={markComplete}
                    >
                      {item?.description}
                    </CapabilityCard>
                  ))}
                </div>
                {capabilitiesList?.length > 2 && (
                  <ArrowForwardIosIcon
                    className={classNames(
                      'mx-2 fs-2 bg-dark text-white p-1 rounded-3 opacity-75',
                      c.cursorPointer
                    )}
                    onClick={() => (swiper.current.scrollLeft += 500)}
                  />
                )}
              </div>
              <Text
                textType="t0"
                classToOverride={classNames(
                  'pt-4 ps-5',
                  c.fontWeight600,
                  c.capabilitiesCount
                )}
              >
                {`Number of Capabilities : ${capabilitiesList.length}`}
              </Text>
            </>
          ) : (
            !showLoader &&
            !capabilitiesList.length && <NoCapabilitiesAssignedUI />
          ))}
        {showLoader && (
          <div>
            <Skeleton
              variant="rectangular"
              height={273}
              className="mt-4 mx-4 rounded"
            />
          </div>
        )}
        {tabIndex === 1 && <NoCapabilitiesAssignedUI />}
      </div>
    </>
  );
};

export default CapabilitiesToStudy;

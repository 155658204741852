/**
 * Navigation bar for the app
 * @function Header
 */

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import c from './header.Module.scss';
import { Resources } from '../../config/Resources';
import Headings from '../Shared/Headings';
import Button from '@mui/material/Button';
import SchoolIcon from '@mui/icons-material/School';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import {
  IconButton,
  Badge,
  Avatar,
  InputBase,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';
import Help from './Help';
import Box from '@mui/material/Box';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Skeleton from '@mui/material/Skeleton';
import Text from '../Shared/Text';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DropdownMenu } from '../Home/CreateAccount/index';
// import { CHROME_EXTENSION_ID } from '../../config/Constants';
import SearchIcon from '@mui/icons-material/Search';
import { fetchCategories } from '../../helpers/LearningPlans/LearningPlansModalBox';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  SET_SELECTED_CATEGORY,
} from '../../redux/Actions/Common';
import { ROLE_IDS } from '../../config/Constants';
import { includes } from 'lodash';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import Notification from './Notification';
import RequestModalContainer from './Notification/NotificationContainer/RequestModalContainer';
import LeaveRequestStatus from './Notification/NotificationContainer/LeaveRequestStatus';
import { useMutation } from 'react-query';
import {
  getNotification,
  updateNotifications,
} from '../../helpers/Home/Notifications';

const Header = () => {
  const history = useHistory();
  const { generalInformation } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  // const [Port, setPort] = useState(null);
  const { profile_image_url, user_name } = useSelector(
    (state) => state?.generalInformation
  );
  // const [searchBar, setSearchBar] = useState('');
  const [categories, setCategories] = useState([]);
  const filter = createFilterOptions();
  const [value, setValue] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const NotificationRefrence = useRef(null);
  const [notificationData, setNotificationData] = useState([]);
  const [requestModalStatus, setRequestModalStatus] = useState(false);
  const [selectGroup, setSelectGroup] = useState('');
  const [additionalDetailsData, setAdditionalDetailsData] = useState({});
  const userData = useSelector((state) => state.generalInformation);
  useEffect(() => {
    getFetchCategories();
  }, []);

  const getFetchCategories = async () => {
    const response = await fetchCategories();
    if (response?.data) {
      setCategories(response?.data);
    }
  };

  const handleNotification = (param) => {
    setIsNotification(param);
  };

  const getNotifications = async (id) => {
    const notifications = await getNotification(id);
    setNotificationData(notifications.data);
  };

  useEffect(() => {
    if (userData.id) getNotifications(userData.id);
  }, [userData]);

  const { mutate } = useMutation(updateNotifications, {
    onSuccess: async () => {
      getNotifications(userData.id);
    },
  });

  const handleClick = (id) => {
    mutate(id);
  };

  return (
    <div
      className={classNames(
        'w-100 position-fixed top-0 px-5 d-flex justify-content-between align-items-center',
        c.header
      )}
    >
      <div
        className={classNames('d-flex align-items-center', c.cursorPointer)}
        onClick={() => {
          history.push('/home');
          googleAnalyticsTriggerFn(
            'Button',
            'Ahura Icon',
            'Ahura Icon on Navbar clicked'
          );
        }}
      >
        <img src={Resources.images.AhuraHeaderImage} alt="ahura logo" />
        <span className="ms-1">
          <Headings
            headingType="h5"
            classToOverride={classNames(c.ahuraSymbol, c.fontWeight600)}
          >
            AHURA
          </Headings>
        </span>
      </div>
      {[
        ROLE_IDS.CLIENT_ADMIN,
        ROLE_IDS.CLIENT_OWNER,
        ROLE_IDS.PARENT_ADMIN,
        ROLE_IDS.PARENT_OWNER,
        ROLE_IDS.SUPER_OWNER,
      ].includes(generalInformation.role_id) && (
        <Button
          className={classNames('fw-bold', c.adminBtn)}
          onClick={() => {
            history.push('/networks');
            googleAnalyticsTriggerFn(
              'Button',
              'Admin Button',
              'Admin Button on Navbar clicked'
            );
          }}
          variant="contained"
        >
          <Headings headingType="h5">Admin</Headings>
        </Button>
      )}
      <Button
        className={classNames('fw-bold', c.WatchTutBtn)}
        onClick={() => {
          window.open(
            'https://www.youtube.com/playlist?list=PLqYe_Fb9Fe4LlcgiDVNUg6El_4mZBQsuO',
            '_blank'
          );
          googleAnalyticsTriggerFn(
            'Button',
            'Watch Tutorial',
            'Watch Tutorial button clicked'
          );
        }}
        variant="contained"
      >
        <Headings headingType="h5">Watch tutorials</Headings>
      </Button>

      <div className="d-flex align-items-center">
        <div
          className={classNames(
            'd-flex align-items-center mx-3 bg-white',
            c.AutoSearchContainer
          )}
        >
          <Autocomplete
            value={value}
            onInputChange={(event, newValue) => {
              {
                if (typeof newValue === 'string') {
                  setValue({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }
            }}
            onChange={(_, value) => {
              if (value) {
                history.push({
                  pathname: '/content',
                });
                googleAnalyticsTriggerFn(
                  'Search',
                  'Search Bar',
                  'Searched from Navbar'
                );
              }
              dispatch(SET_SELECTED_CATEGORY(value));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                history.push({
                  pathname: '/content',
                });
                dispatch(SET_SELECTED_CATEGORY(value));
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option.name
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="Search"
            options={categories}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option?.name;
            }}
            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                InputLabelProps={{ shrink: false }}
                helperText={null}
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingTop: 2,
                    paddingBottom: 2,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            className={classNames('w-100', c.AutoSearch)}
          />
        </div>

        <div
          className={classNames(
            'd-flex align-items-center justify-content-center'
          )}
        >
          <Button
            endIcon={<ArrowDropDownCircleOutlinedIcon />}
            className={classNames('fw-bold', c.HelpBtn)}
            onClick={(e) => {
              setHelpAnchorEl(e.currentTarget);
              googleAnalyticsTriggerFn('Button', 'Help', 'Help button clicked');
            }}
          >
            <Headings headingType="h5">Help</Headings>
          </Button>

          <IconButton aria-label="notifications" classes={{ root: 'm-1' }}>
            <Badge
              className={classNames(c.badge)}
              classes={{ badge: 'mt-1 me-1 p-1' }}
              onClick={() => handleNotification(!isNotification)}
              variant="dot"
              invisible={
                notificationData &&
                !notificationData?.some(
                  (notification) => !notification?.is_read
                )
              }
              color="primary"
            >
              <NotificationsNoneIcon ref={NotificationRefrence} />
              <Notification
                isNotification={isNotification}
                notificationData={notificationData}
                setNotificationData={setNotificationData}
                handleNotification={handleNotification}
                handleClick={handleClick}
                ComponentRef={NotificationRefrence.current}
                requestModalStatus={requestModalStatus}
                setRequestModalStatus={setRequestModalStatus}
                additionalDetailsData={additionalDetailsData}
                setIsNotification={setIsNotification}
                setAdditionalDetailsData={setAdditionalDetailsData}
              />
              {notificationData &&
                notificationData?.length > 0 &&
                !additionalDetailsData?.is_read && (
                  <RequestModalContainer
                    requestModalStatus={requestModalStatus}
                    setRequestModalStatus={setRequestModalStatus}
                  >
                    <LeaveRequestStatus
                      setRequestModalStatus={setRequestModalStatus}
                      additionalDetailsData={additionalDetailsData}
                      handleClick={handleClick}
                    />
                  </RequestModalContainer>
                )}
            </Badge>
          </IconButton>
        </div>

        <Box className={classNames('mx-3', c.headerDivider)} />

        {!profile_image_url && !user_name ? (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<SchoolIcon />}
            className={`${c.signInBtn} rounded-pill`}
            onClick={() => {
              localStorage.setItem('token', '');
              googleAnalyticsTriggerFn(
                'Button',
                'Sign In',
                'Sign In button Other Page'
              );
              return history.push('/sign-in');
            }}
          >
            Sign In
          </Button>
        ) : (
          <div
            className={classNames('d-flex align-items-center', c.cursorPointer)}
          >
            <Avatar alt="profile picture" src={profile_image_url} />
            <div
              className="ms-2"
              onClick={() => {
                history.push('/profile');
                googleAnalyticsTriggerFn(
                  'Button',
                  'Profile',
                  'Clicked on Profile'
                );
              }}
            >
              <Text textType="t0" classToOverride={c.fontWeight600}>
                {user_name}
              </Text>
              <Text
                textType="t4"
                classToOverride={classNames(c.fontWeight600, c.userAccount)}
              >
                Personal Account
              </Text>
            </div>
          </div>
        )}

        {!profile_image_url && !user_name ? (
          <div></div>
        ) : (
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              googleAnalyticsTriggerFn(
                'Button',
                'Dropdown',
                'Dropdown button on navbar clicked'
              );
            }}
            className="ms-3"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}

        <Help anchorEl={helpAnchorEl} setAnchorEl={setHelpAnchorEl} />

        <DropdownMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </div>
    </div>
  );
};
export default Header;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  menuItems,
  // TopLearnersData,
} from '../../../../../../helpers/Home/Community';
import AvatarWithInfo from '../../../../../Layout/AvatarWithInfo';
import Headings from '../../../../../Shared/Headings';
import classNames from 'classnames';
import c from '../../../../community/community.Module.scss';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Text from '../../../../../Shared/Text';
import { useHistory } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../../../../../utils/utility';

const CommunityTopLearners = ({ CommunityLearnersData: TopLearnersData }) => {
  const history = useHistory();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {menuItems.map((data, index) => (
            <TableCell
              key={index}
              className={classNames('fw-bolder px-3 py-0', c.learnerHeader)}
              align="center"
            >
              {data.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {TopLearnersData && TopLearnersData.length > 0 ? (
          TopLearnersData.map((row, index) => (
            <TableRow
              key={index}
              className={classNames('py-0')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" className={classNames('p-0')}>
                <AvatarWithInfo
                  src={row?.profile_image_url}
                  alt="Python"
                  width="50px"
                  height="50px"
                  head={
                    <Headings
                      text={row?.user_name}
                      classToOverride="fw-bolder"
                    />
                  }
                  classForHead={classNames(c.TopLearnersName)}
                  leftMarginClass={classNames('ms-2')}
                />
              </TableCell>
              <TableCell align="center">
                <div>
                  <div className={classNames(c.TopLearnersColumn2)}>
                    {row?.capability || 0} capabilities
                  </div>
                  <div className={classNames('mt-1', c.time)}>
                    <WatchLaterIcon
                      className={classNames('me-1', c.WatchIcon)}
                    />{' '}
                    {`${Math.floor(row?.duration / 60) || 0} hrs ${
                      row?.duration % 60 || 0
                    } mins`}
                  </div>
                </div>
              </TableCell>
              <TableCell align="center">
                <div
                  className={classNames(
                    c.TopLearnersColumn3,
                    row?.user_client_id ? c.cursorPointer : c.notAllowed,
                    'fw-bold'
                  )}
                  onClick={() => {
                    if (row?.user_client_id)
                      history.push(`/employee-details/${row?.user_client_id}`);
                    googleAnalyticsTriggerFn(
                      'Button',
                      'Top Learners employees',
                      'Top Learners view employee details clicked'
                    );
                  }}
                >
                  <u>View Details</u>
                </div>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Text
          key={1}
            textType="t0"
            classToOverride={classNames(c.primaryColor, 'fw-bold m-5')}
          >
            No Data Available!
          </Text>
        )}
      </TableBody>
    </Table>
  );
};

export default CommunityTopLearners;

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  getAllCategories,
  getAllCourses,
  getAllCourses1,
} from '../../../helpers/Content';
import { useHistory } from 'react-router-dom';
import { fetchCategories } from '../../../helpers/LearningPlans/LearningPlansModalBox';
import Text from '../../Shared/Text';
import c from '../home.Module.scss';
import { getExpertiseData } from '../../../helpers/Home/ExpertiseJourney2.0';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
const CapabilitiesTab = (props) => {
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [actualData, setActualData] = useState([]);

  const handleChange = (event) => {
    const item = event.target.value;
    if (item) {
      history.push(`/capabilities/${item.id}?category_name=${item.name}`);
    }
    googleAnalyticsTriggerFn(
      'Select',
      'Select Capability',
      `${item.name} selected`
    );
  };

  const corp_id = useSelector((state) => state?.generalInformation?.client_id);

  const OnMount = async () => {
    const response = await getExpertiseData(corp_id, 'corp');
    if (response) {
      setCourses(response);
    }
    const res = await getAllCategories();
    if (res) {
      setCourseData(res.data);
    }
  };

  useEffect(() => {
    OnMount();
  }, []);

  useEffect(() => {
    // const arr = [];
    // if (courses.length > 0 && courseData.length > 0) {
    //   const newData = courses.filter((val1, ind1) => {
    //     arr.push(ind1);
    //     courseData.filter((val2, ind2) => {
    //       if (
    //         val1.capability.toLowerCase() ===
    //         val2.campaign_category.toLowerCase()
    //       ) {
    //         courses[ind1]['id'] = val2.category_id;
    //         courses[ind1]['campaign_category'] = val2.campaign_category;
    //         return;
    //       }
    //     });
    //   });

    //   if (arr.length === courses.length) {
    //     setActualData(courses);
    //   }
    // }

    const intersectionData = []; // new array
    courses.filter((expertiseDataItem) => {
      // enter loop 1
      courseData.filter((categoryDataItem) => {
        // enter loop 2
        // traverse whole array and check if data item from array 1 is present.
        // if yes push object in new array
        if (
          expertiseDataItem.capability.toLowerCase() ===
          categoryDataItem.name.toLowerCase()
        ) {
          intersectionData.push({
            id: categoryDataItem.id,
            name: categoryDataItem.name,
          });
        }
      });
    });

    setActualData(intersectionData);
  }, [courses, courseData]);

  return (
    <>
      <div className="mt-5 p-5" style={{ backgroundColor: '#ffffff' }}>
        <Text
          textType="t1"
          classToOverride={classNames(
            'me-auto bd-highlight ms-3',
            c.CapabilitiesTab_Heading
          )}
        >
          Capabilities{' '}
        </Text>
        <FormControl
          sx={{ minWidth: 500, marginTop: '20px', marginLeft: '15px' }}
        >
          <InputLabel id="select-capability">Select Capability</InputLabel>
          <Select
            defaultValue=""
            labelId="select-capability"
            fullWidth
            input={
              <OutlinedInput id="select-capability" label="Select Capability" />
            }
            id="select-capability"
            // displayEmpty
            onChange={handleChange}
            inputProps={{
              className: classNames('bg-transparent', c.profileInputField),
            }}
          >
            {actualData &&
              actualData.map((item, ind) => {
                return (
                  item.id && (
                    <MenuItem key={ind} value={item}>
                      {item?.name}
                    </MenuItem>
                  )
                );
              })}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default CapabilitiesTab;

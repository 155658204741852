import classNames from 'classnames';
import Text from '../Text';
import Headings from '../Headings';
import c from '../shared.Module.scss';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import LanguageIcon from '@mui/icons-material/Language';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from 'react';
const Nudges = ({ nudgesData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (nudgesData) {
      const newData = Object.keys(nudgesData).map((key, index) => ({
        objKey: key,
        objValue: nudgesData[key],
      }));
      setData(newData);
    }
  }, [nudgesData]);
  return (
    <div className={classNames('h-100 w-100 bg-white rounded-3 row g-0')}>
      <Headings
        headingType="h4"
        classToOverride={`row g-0 fw-bold px-4 py-3 ${c.nudgesHeading}`}
      >
        Nudges
      </Headings>
      <div className="w-100 d-flex grid gap-5 flex-wrap py-5  px-5">
        {data && data.length > 0 ? (
          data.map(({ objKey, objValue, index }) => (
            <div
              key={`${objKey}-${index}`}
              className={classNames('rounded-2 py-2 border', c.nudgesBox)}
            >
              <div className="d-flex justify-content-between px-4">
                <Text>{objValue}</Text>
                {objKey === 'auto_stop' && <StopCircleIcon />}
                {objKey === 'looking_away' && <SentimentDissatisfiedIcon />}
                {objKey === 'off_chrome' && <WebAssetOffIcon />}
                {objKey === 'off_screen' && <StopScreenShareIcon />}
                {objKey === 'social_media' && <LanguageIcon />}
                {objKey === 'web_surfing' && <RssFeedIcon />}
                {objKey === 'idle' && <AccessTimeIcon />}
              </div>
              <div className="text-center py-1 mx-auto text-capitalize">
                {objKey.includes('_')
                  ? `${objKey.split('_')[0]} ${objKey.split('_')[1]}`
                  : objKey}
              </div>
            </div>
          ))
        ) : (
          <Headings headingType="h4" classToOverride="row g-0 fw-bold mx-auto">
            No record found
          </Headings>
        )}
      </div>
    </div>
  );
};

export default Nudges;

import { API_URLS } from '../../config/API_URLS/api_urls';
import axiosInstance from './axiosInstance';
const baseURL = API_URLS?.baseURL;

const getApiCall = async (
  url,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl?.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      const res = await axiosInstance.get(requestUrl, { headers });
      return res;
    } else {
      const res = await axiosInstance.get(requestUrl);
      return res;
    }
  } catch (err) {
    return err;
  }
};

const postApiCall = async (
  url,
  reqBody,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return await axiosInstance.post(requestUrl, reqBody, { headers });
    } else {
      return await axiosInstance.post(requestUrl, reqBody);
    }
  } catch (err) {
    return err;
  }
};

const putApiCall = async (
  url,
  reqBody,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return await axiosInstance.put(requestUrl, reqBody, { headers });
    } else {
      return await axiosInstance.put(requestUrl, reqBody);
    }
  } catch (err) {
    return err;
  }
};

const deleteApiCall = async (
  url,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return await axiosInstance.delete(requestUrl, { headers });
    } else {
      return await axiosInstance.delete(requestUrl);
    }
  } catch (err) {
    return err;
  }
};

export { getApiCall, postApiCall, putApiCall, deleteApiCall };

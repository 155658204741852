import React, { useEffect, useState } from 'react';
import Headings from '../../components/Shared/Headings';
import classNames from 'classnames';
import c from './content.module.scss';
import {
  InputBase,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CourseCard from '../../components/Shared/CourseCardV2';
import { getAllCourses } from '../../helpers/Content';
import _ from 'lodash';
import { HTML_TAG_PATTERN } from '../../config/Regex/regex';
import { useDebouncedCallback } from 'use-debounce';
import { handleStartSession } from '../../helpers/ExtensionHandler';
import { fetchCategories } from '../../helpers/LearningPlans/LearningPlansModalBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsSessionDetailsOpen,
  setIsExtensionBtnEnabled,
} from '../../redux/Actions/ExtensionHandler';
import SessionDetails from '../../components/Shared/SessionDetails';
import { useLocation } from 'react-router-dom';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import { Helmet } from 'react-helmet';

const ContentPage = (props) => {
  const { search: querySearchParams } = useLocation();
  const searchParams = new URLSearchParams(querySearchParams);

  const selectedCategory = useSelector((state) => state?.selectedCategory);
  const IsExtensionBtnEnabled = useSelector(
    (state) => state?.IsExtensionBtnEnabled
  );
  const userData = useSelector((state) => state.generalInformation);
  const [search, setSearch] = useState(selectedCategory?.name || '');
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const isSessionDetailsOpen =
    useSelector((state) => state?.isSessionDetailsOpen) || false;
  const barData = useSelector((state) => state?.barData[0]) || [];
  const sessionInfo = useSelector((state) => state?.sessionData[0]) || [];
  const ExtensionState =
    useSelector((state) => state?.IsExtensionActive) || false;
  useEffect(() => {
    getFetchCategories();
  }, []);

  useEffect(() => {
    // Set OG tags dynamically
    // document.title = 'Ahura AI: Meet your Future';
    // document.querySelector('meta[property="og:description"]')?.setAttribute('content', 'Expertly curated content for exciting new jobs of tomorrow');
    // document.querySelector('meta[property="og:image"]')?.setAttribute('content', 'https://ahuraai.com/wp-content/uploads/2022/07/african-american-girl-1024x576.png');
  }, []);

  useEffect(() => {
    onSearchChangeHandle(selectedCategory?.name || '', true);
  }, [selectedCategory]);

  console.log(selectedCategory?.name, 'selected category');

  const getFetchCategories = async () => {
    const response = await fetchCategories();
    if (response?.data) {
      setCategories(response?.data);
    }
  };
  // const debounced = useDebouncedCallback(
  // (value) => {
  // setSearch(value);
  // },
  // // delay in ms
  // 1000
  // );

  useEffect(() => {
    getCourses();
    if (searchParams.get('searchContent') != null) {
      setSearch(searchParams.get('searchContent'));
      setTimeout(async () => {
        try {
          const res = await getAllCourses(searchParams.get('searchContent'));
          setCourses(res);
          setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error('Error fetching courses:', error);
          setLoading(false); // Set loading to false even if there's an error
        }
      }, 500);
    }
  }, []);

  // useEffect(async()=>{
  //   console.log('Cons%%%%%%%%%%%%%', searchParams.get('searchContent'))
  //   if(searchParams.get('searchContent') != null)
  //   setSearch(searchParams.get('searchContent'));
  //   setTimeout(async()=>{
  //   const res = await getAllCourses(searchParams.get('searchContent'));
  //   setCourses(res);
  //   },500)

  // },[searchParams.get('searchContent')])

  const getCourses = async () => {
    try {
      setLoading(true); // Start loading
      const res = await getAllCourses(search);
      setCourses(res);
      setLoading(false); // End loading
    } catch (error) {
      console.error('Error fetching courses:', error);
      setLoading(false); // End loading
    }
  };

  const onSearchChangeHandle = async (value, courseUpdate = false) => {
    let searchVal = '';
    if (typeof value === 'string') {
      searchVal = value || '';
    } else {
      searchVal = value?.name || '';
    }
    setSearch(searchVal || '');
    if (courseUpdate) {
      const res = await getAllCourses(searchVal);
      setCourses(res);
      googleAnalyticsTriggerFn(
        'Search',
        'Ahura Courses',
        'Ahura Courses searched on content page'
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content={`Ahura AI: Meet your Future`} />
        <meta
          property="og:description"
          content="I recommend this course on Ahura AI. Find expertly curated content for exciting new jobs of tomorrow"
        />
        <meta
          property="og:image"
          content="https://ahuraai.com/wp-content/uploads/2022/07/african-american-girl-1024x576.png"
        />
      </Helmet>
      <div className="p-1">
        <Headings headingType={'h30'} classToOverride="fw-bold">
          Learn
        </Headings>
        <div className="row mt-3">
          <div className="col-7">
            <div
              className={classNames(
                'd-flex align-items-center bg-white',
                c.AutoSearchContainer
              )}
            >
              <Autocomplete
                // disablePortal
                freeSolo
                id="Search"
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option?.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  console.log(options, params, 'line 157');
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) =>
                      inputValue.toLowerCase() == option.name.toLowerCase()
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `${inputValue}`,
                    });
                  }
                  return filtered;
                }}
                options={categories}
                sx={{ width: 'w-100' }}
                size="small"
                value={search}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    helperText={null}
                    placeholder="Type Topic. I.e.  capability/ skill to study"
                  />
                )}
                className={classNames('w-100', c.AutoSearch)}
                onInputChange={(_, value) => {
                  onSearchChangeHandle(value);
                }}
                onChange={(_, value) => {
                  onSearchChangeHandle(value, true);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSearchChangeHandle(search, true);
                  }
                }}
              />
            </div>
            <span className={classNames(c.searchBarTextBelow)}>
              e.g. Math, Finance Management, Growth Mindset. Less than 3 words,
              no URL
            </span>
          </div>
          <div className="col-2">
            <Button
              disabled={!search}
              title="Enter topic to enable"
              variant="contained"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'all',
                },
              }}
              onClick={() => {
                getCourses();
                googleAnalyticsTriggerFn(
                  'button',
                  'Search Ahura',
                  'Search Ahura button clicked on Content page'
                );
              }}
              className="h-100 w-100 text-capitalize fw-bold"
            >
              Search Ahura
            </Button>
          </div>
          <div className="col-3">
            <Button
              disabled={
                !search ||
                ExtensionState ||
                IsExtensionBtnEnabled ||
                !userData?.id
              }
              title="Enter topic to enable"
              variant="contained"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'all',
                },
              }}
              onClick={(e) => {
                if (search !== '') {
                  handleStartSession(e, search);

                  // https://www.google.com/search?q=angular
                  googleAnalyticsTriggerFn(
                    'button',
                    'Start AI Assistant',
                    'Start AI Assistant button clicked on Content page'
                  );
                }
              }}
              className="h-100 w-100 text-capitalize fw-bold"
            >
              Start AI Assistant
            </Button>
          </div>
          <div className="row mt-2">
            {courses.map((course) => (
              <div className="col-lg-3 p-1" key={course?.id}>
                <CourseCard
                  id={course?.id}
                  courseCategory={course?.campaign_category}
                  heading={course?.heading}
                  courseCategoryId={course?.category_id}
                  desc={_?.replace(course?.description, HTML_TAG_PATTERN, '')}
                  peers={0}
                  quizzes={course?.quiz_count}
                  sections={course?.section_count}
                  image_url={course?.image_url}
                />
              </div>
            ))}
            {loading ? (
              <div className="col-12 text-center">
                <br />
                <br />
                <CircularProgress size={200} />
              </div>
            ) : (
              courses.map((course) => (
                <div className="col-lg-3 p-1" key={course?.id}>
                  <CourseCard
                    id={course?.id}
                    courseCategory={course?.campaign_category}
                    heading={course?.heading}
                    courseCategoryId={course?.category_id}
                    desc={_?.replace(course?.description, HTML_TAG_PATTERN, '')}
                    peers={0}
                    quizzes={course?.quiz_count}
                    sections={course?.section_count}
                    image_url={course?.image_url}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {isSessionDetailsOpen && (
        <SessionDetails
          sessionInfo={sessionInfo}
          barInfo={barData}
          setModal={() => dispatch(setIsSessionDetailsOpen(false))}
        />
      )}
    </>
  );
};

export default ContentPage;

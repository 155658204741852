import React, { useEffect } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { getUserDetails } from '../../helpers/AppRoute';
import Loader from '../Layout/Loader';
import Header from '../Header';
import Sidebar from '../Sidebar';
import classNames from 'classnames';
import c from './appRoute.Module.scss';
import { useSelector } from 'react-redux';
import { ROLE_IDS } from '../../config/Constants';
import { decodeToken } from 'react-jwt';
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { onLogOut } from '../../redux/Actions/UserLoggedOut';
import { SET_TOAST_ERROR } from '../../redux/Actions/Common';
import { extUpdateUI } from '../../helpers/ExtensionHandler';
import { setIsExtensionActive } from '../../redux/Actions/ExtensionHandler';
import { CHROME_EXTENSION_ID } from '../../config/Constants';

const AppRoute = ({ component: Component, isAdmin = false, ...rest }) => {
  const token = localStorage.getItem('token');
  const { state, search } = rest?.location;
  const location = useLocation();
  const dispatch = useDispatch();

  const isTokenValid = () => {
    try {
      const { exp } = jwtDecode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (exp < currentTimestamp) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('Error in isTokenValid method', e);
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  const history = useHistory();
  const decodedToken = decodeToken(token);
  const { generalInformation } = useSelector((state) => state);

  useEffect(() => {
    if (isTokenValid()) {
      history.push('/sign-in');
      localStorage.clear();
      dispatch(onLogOut());
    } else {
      getUserDetails(token);
      document.addEventListener('ahura_start_session_event', (event) => {
        if (event.detail) {
          let data = event.detail;
          if (data.extension_id && data.extension_id === CHROME_EXTENSION_ID) {
            console.log('start session event recieved from extension');
            dispatch(setIsExtensionActive(true));
          }
        }
      });
      document.addEventListener('ahura_stop_session_event', (event) => {
        console.log('stop session event recieved from extension');
        if (event.detail) {
          let detail = event.detail;
          if (detail.error) {
            dispatch(
              SET_TOAST_ERROR({
                error: true,
                message: detail.data,
              })
            );
            extUpdateUI();
          } else if (detail.extension_id && detail.extension_id === CHROME_EXTENSION_ID) {
            extUpdateUI();
          }
        }
      });
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isAdmin && decodedToken?.role_id === ROLE_IDS?.USER) {
      history.push('/manager-dashboard');
    }
  }, [decodedToken]);

  useEffect(() => {
    document
      .getElementById('parent-con')
      ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [Component]);

  return (
    <Route {...rest}>
      {isAdmin &&
      (decodedToken?.role_id === ROLE_IDS?.USER ||
        generalInformation.role_id === ROLE_IDS?.USER) ? (
        <Redirect to={'/'} />
      ) : (
        <>
          {' '}
          <Loader />
          <Header />
          {!isAdmin && <Sidebar />}
          <div
            className={classNames(
              !isAdmin ? c.pageContainer : c.adminPageContainer
            )}
            id="parent-con"
          >
            {state ? (
              <Component state={state} />
            ) : search ? (
              <Component search={search} />
            ) : (
              <Component />
            )}
          </div>
        </>
      )}
    </Route>
  );
};

export default AppRoute;

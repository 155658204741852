/**
 * @function Sessions
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SessionDetails from '../../Shared/SessionDetails';
import classNames from 'classnames';
import c from '../home.Module.scss';
import Text from '../../Shared/Text';
import CustomSelect from '../../Layout/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable from '../../Shared/Table';
import {
  columns,
  manager_columns,
} from '../../../helpers/Home/AssignedCapabilities';
import SessionsTable from './Table';
import moment from 'moment';
import { getTimeInHMS, googleAnalyticsTriggerFn } from '../../../utils/utility';
import { ALL_USER_SESSIONS } from '../../../config/API_URLS/api_urls';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SET_TOAST_ERROR } from '../../../redux/Actions/Common';
import { useHistory } from 'react-router-dom';
import { sessionRecord } from '../../../redux/Actions/SessionRecord';
import { setSessionsData } from '../../../redux/Actions/AllSessionData';
import { filterOptions } from '../../../helpers/Home/Sessions';

const Sessions = ({
  extId = null,
  isManager = false,
  employee_full_name = '',
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sessionsInformation = useSelector((state) => state?.sessionData) || [];
  // const barData = useSelector((state) => state?.barData) || [];

  const [sessionsTableData, setSessionsTableData] = useState([]);
  const [sessionInfo, setSessionInfo] = useState({});
  const [barInfo, setBarInfo] = useState({});
  const [openModal, setModal] = useState(false);
  const [sortedTableData, setSortedTableData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tablePageNo, setTablePageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sort, setSort] = useState(false);
  const [sortColumn, setSortColumn] = useState('date');
  const [filter, setFilter] = useState('all');
  // useEffect(
  //   () => setSessionsTableData(sessionsInformation),
  //   [sessionsInformation]
  // );

  useEffect(() => {
    if (extId) {
      get_all_sessions(extId);
    }
    // eslint-disable-next-line
  }, [
    sessionsInformation,
    extId,
    rowsPerPage,
    tablePageNo,
    sortColumn,
    sort,
    filter,
  ]);

  const get_all_sessions = async (extId) => {
    try {
      const res = await axios.get(ALL_USER_SESSIONS(extId), {
        params: {
          items_per_page: rowsPerPage,

          page_no: tablePageNo + 1,

          column: sortColumn,

          order: sort ? 'asc' : 'desc',
          month: filter,
        },
      });

      if (res && res?.data?.data?.sessions) {
        dispatch(setSessionsData(res?.data?.data?.sessions));
        setSessionsTableData(res?.data?.data?.sessions);
        console.log('Session Table Data', res?.data?.data?.sessions[0]);
      }
    } catch (error) {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Something went wrong while fetching all sessions',
        })
      );
    }
  };

  const sortSessionData = (sessions) => {
    return sessions?.sort(
      (a, b) => new Date(b?.time_started) - new Date(a?.time_started)
    );
  };

  const onViewSessionClick = (data) => {
    dispatch(sessionRecord(data));

    // const sessionReport = sessionsInformation?.find(
    //   (session) => session?.session_id === event?.target?.id
    // );
    // const barStats = barData?.find(
    //   (status) => status?.session_id === event?.target?.id
    // );
    // const { ...barStatsRest } = barStats;
    // setSessionInfo(sessionReport);
    // setBarInfo(barStatsRest);
    // setModal(true);
    // Send this too

    history.push(`/sessions/${data?.session_id}`,  { employee_full_name });
  };

  const handleSorting = (columnId) => {
    const areNotEqual = !_.isEqual(sessionsTableData, sortedTableData);
    let newTableData = [...(areNotEqual ? sessionsTableData : sortedTableData)];
    if (columnId === 'time_finished') {
      newTableData = newTableData?.map((item) => ({
        ...item,
        time_finished: new Date(item?.time_finished),
      }));
    }
    newTableData?.sort((a, b) =>
      a[columnId] > b[columnId]
        ? areNotEqual
          ? 1
          : -1
        : b[columnId] > a[columnId]
        ? areNotEqual
          ? -1
          : 1
        : 0
    );
    if (areNotEqual) {
      setSortedTableData(newTableData);
    }
    setSessionsTableData(newTableData);
  };

  // export const columns = [
  //   { id: 'time_finished', label: 'Date' },
  //   { id: 'description', label: 'Topic' },
  //   { id: 'source', label: 'Source' },
  //   { id: 'duration', label: 'Duration' },
  //   { id: 'assessment', label: 'Assessment' },
  //   { id: 'actions', label: 'Actions' },
  // ];

  // date,
  // topic,
  // duration,
  // sessionId,
  // handleViewSession,
  // data,
  // isManager = false,

  const handleSort = (column_id) => {
    if (column_id === 'time_finished') {
      setSort(!sort);
      setSortColumn('date');
    }
    if (column_id === 'description') {
      setSort(!sort);
      setSortColumn('topic');
    }
    if (column_id === 'duration') {
      setSort(!sort);
      setSortColumn('duration');
    }
    if (column_id === 'nudges_per_x_min') {
      setSort(!sort);
      setSortColumn('nudges_per_x_min');
    }
    if (column_id === 'struggling_emotions') {
      setSort(!sort);
      setSortColumn('struggling_emotions');
    }
  };

  return (
    <>
      {openModal && (
        <SessionDetails
          sessionInfo={sessionInfo}
          barInfo={barInfo}
          setModal={setModal}
        />
      )}
      <div className={classNames('bg-white rounded', c.sessions)}>
        <div className="row g-0 p-4 align-items-center">
          <div className="col-9">
            <Text textType="t0" classToOverride={classNames('mb-3', c.heading)}>
              Sessions
            </Text>
            {/* <Text
              textType="t2"
              classToOverride={classNames('fw-normal', c.fade)}
            >
              Another line here for description
            </Text> */}
          </div>
          <div className="col-3">
            <CustomSelect
              label="Filter"
              menuItems={filterOptions}
              dropdownIcon={FilterAltIcon}
              value={filter}
              onChange={(e) => {
                setFilter(e?.target?.value);
                googleAnalyticsTriggerFn(
                  'Filter',
                  'Sessions Filter',
                  'Sessions Filter changed'
                );
              }}
            />
          </div>
        </div>
        <div className={classNames('row g-0', c.tableContainer)}>
          <CustomTable
            columns={isManager ? manager_columns : columns}
            onClick={handleSort}
            pageNo={tablePageNo}
            setTablePageNo={setTablePageNo}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          >
            {sessionsTableData?.map((item) => {
              return (
                <SessionsTable
                  key={item?.session_id}
                  sessionId={item?.session_id}
                  date={moment(item?.time_finished).format('MM/DD/YYYY')}
                  topic={item?.description}
                  duration={
                    Boolean(item?.duration) ? getTimeInHMS(item?.duration) : 0
                  }
                  nudges_per_x_min={
                    item?.nudge_yes_count
                      ? Math.round(
                          item.nudge_yes_count /
                            Math.ceil(item.duration_secs / (30 * 60))
                        )
                      : 0 //Nudges per 30 Mins so 30*60
                  }
                  struggling_emotions={
                    item?.struggling_emotion_count
                      ? Math.round(
                          (item.struggling_emotion_count /
                            item.total_emotion_count) *
                            100
                        ) + '%'
                      : 'NA' //Percentage
                  }
                  handleViewSession={onViewSessionClick}
                  data={item}
                  isManager={isManager}
                />
              );
            })}
          </CustomTable>
        </div>
      </div>
    </>
  );
};

export default Sessions;

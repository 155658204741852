import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import classNames from 'classnames';
import c from '../home.Module.scss';
import Text from '../../Shared/Text';
import Headings from '../../Shared/Headings';
import {
  options,
  quadrants,
  labels,
  getExpertiseData,
} from '../../../helpers/Home/ExpertiseJourney2.0';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getExpertiseDataGraph } from '../../../helpers/Home/UserCapabilities/index';
import { getAllCourses } from '../../../helpers/Content';
import { useSelector } from 'react-redux';
import { googleAnalyticsTriggerFn } from '../../../utils/utility';
import { ROLE_IDS } from '../../../config/Constants';
// import { expertiseJourneyMockdata } from '../../../MockData/Home/ExpertiseJourney';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ExpertiseJourney2 = ({ external_id, entity, setTabIndex }) => {
  const location = useLocation();
  const corp_id = useSelector((state) => state?.generalInformation?.client_id);
  const userCorporateId = useSelector((state) => state?.userCorporateDetails);
  const roleId = useSelector((state) => state?.generalInformation?.role_id);
  const platform_id = useSelector(
    (state) => state?.generalInformation?.platform_id
  );
  const [experData, setExperData] = useState([]);
  const [LoExLoHr, setLoExLoHr] = useState([]);
  const [LoExHiHr, setLoExHiHr] = useState([]);
  const [HiExLoHr, setHiExLoHr] = useState([]);
  const [HiExHiHr, setHiExHiHr] = useState([]);
  const [courses, setCourses] = useState([]);
  const [getCourseName, setCourseData] = useState([]);
  const [actualData, getActualData] = useState([]);
  const [graphData, setGraphData] = useState();

  // useEffect(async () => {
  //   if (external_id) {
  //     getData();
  //   }
  // }, [external_id, entity]);

  const getData = async () => {
    const data = await graphData.filter((item, val) => {
      if (typeof item === 'object') {
        return item;
      }
    });
    setExperData(data);
    const datatoFilter = data?.map((item) => {
      return {
        y: item.statsData[0].average_score,
        x: Number(item.statsData[0].average_poficiency / 60).toPrecision(5),
        topic: item?.capabilityName,
        session: item.statsData[0].total_sessions,
        total_user: item.statsData[0].total_user,
        average_score: item.statsData[0].average_score,
        userOrCorp: entity,
      };
    });
    filterData(datatoFilter);
  };

  const filterData = (data = []) => {
    const loExLoHr = [],
      loExHiHr = [],
      hiExLoHr = [],
      hiExHiHr = [];
    data.forEach((item) => {
      if (item.x <= 0.9 && item.y <= 50) loExLoHr.push(item);
      else if (item.x > 0.9 && item.y <= 50) loExHiHr.push(item);
      else if (item.x > 0.9 && item.y > 50) hiExHiHr.push(item);
      else hiExLoHr.push(item);
    });
    setLoExLoHr(loExLoHr);
    setLoExHiHr(loExHiHr);
    setHiExHiHr(hiExHiHr);
    setHiExLoHr(hiExLoHr);
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'Low expertise Low hours',
        data: [...LoExLoHr],

        backgroundColor: '#FB7F3D',
        pointRadius: 10,
        fill: false,
        pointHoverRadius: 20,
      },
      {
        label: 'Low Expertise High hours',
        data: [...LoExHiHr],
        backgroundColor: '#9595A1',
        pointRadius: 10,
        fill: false,
        pointHoverRadius: 20,
      },
      {
        label: 'High Expertise High hours',
        data: [...HiExHiHr],
        backgroundColor: '#C2A0ED',
        pointRadius: 10,
        fill: false,
        pointHoverRadius: 20,
      },
      {
        label: 'High Expertise Low hours',
        data: [...HiExLoHr],
        backgroundColor: '#00C0B5',
        pointRadius: 10,
        fill: false,
        pointHoverRadius: 20,
      },
    ],
  };

  const OnMount = async () => {
    const response =
      corp_id &&
      userCorporateId.length > 0 &&
      (await getExpertiseData(
        entity === 'user' ? external_id : corp_id,
        entity
      ));
    if (response) {
      setCourses(response);
    }
    const res = await getAllCourses();
    if (res) {
      setCourseData(res);
    }
  };

  useEffect(() => {
    OnMount();
  }, [corp_id, userCorporateId]);

  useEffect(() => {
    const arr = [];

    if (courses.length > 0 && getCourseName.length > 0) {
      const newData = courses.filter((val1, ind1) => {
        arr.push(ind1);
        getCourseName.filter((val2, ind2) => {
          if (
            val1.capability.toLowerCase() ===
            val2.campaign_category.toLowerCase()
          ) {
            courses[ind1]['id'] = val2.category_id;
            courses[ind1]['campaign_category'] = val2.campaign_category;
            return;
          }
        });
      });

      if (arr.length === courses.length) {
        getActualData(courses);
      }
    }
  }, [courses, getCourseName]);


  useEffect(() => {
    async function fetchData() {
      const expertiseData = await getExpertiseDataGraph(
        actualData,
        entity === 'user' && roleId === ROLE_IDS.USER
          ? userCorporateId[userCorporateId.length - 1]?.client_id
          : corp_id,
        entity === 'user' ? external_id : null
      );
      if (expertiseData?.data) {
        // const mainData = await expertiseData.data.filter((item, ind)=>{
        //   if(typeof item === 'object'){
        //     return item
        //   }
        // })
        setGraphData(expertiseData?.data);
      }
    }
    fetchData()
  }, [actualData, corp_id, entity, external_id, roleId, userCorporateId]);

  useEffect(() => {
    if (typeof graphData === 'object') getData();
  }, [graphData]);

  return (
    <div className={classNames(c.expertiseJourneyContainer, 'mt-3 px-5 py-4')}>
      <div className="d-flex justify-content-between mb-3">
        <Headings
          headingType="h4"
          classToOverride={classNames(c.expertiseJourney, 'mb-3 ')}
        >
          Expertise Journey
        </Headings>
        {location.pathname === '/manager-dashboard' && (
          <Button
            variant="contained"
            onClick={() => {
              setTabIndex(2);
              googleAnalyticsTriggerFn(
                'Button',
                'Capabilities Page button',
                'Capabilities Page button clicked in Expertise Joouney'
              );
            }}
            className="text-capitalize fw-bold"
          >
            Capabilities Page
          </Button>
        )}
      </div>
      <div className="row align-items-center">
        <div className="col-11">
          <Scatter options={options} data={data} plugins={[quadrants()]} />
          <div className={classNames('col-12 text-center', c.fontWeight600)}>
            <Text textType="t1">Time Spent (in hours)</Text>
          </div>
        </div>
        <div className="col-1 g-0 ">
          <div className={classNames(c.expertiseLegend, 'p-1 text-center')}>
            <Text
              textType="t3"
              classToOverride={classNames('fw-bold', c.primaryColor)}
            >
              LEGEND
            </Text>
            {/* high Expertise Low Hours */}
            <div className={classNames(c.hiExLoHr, 'w-100')}>
              <Text textType="t3" classToOverride="fw-bold">
                {HiExLoHr.length}
              </Text>
            </div>
            <Text textType="t3">High Expertise Low Hours</Text>

            {/* High Expertise High Hours */}
            <div className={classNames(c.hiExHiHr, 'w-100 mt-2')}>
              <Text textType="t3" classToOverride="fw-bold">
                {HiExHiHr.length}
              </Text>
            </div>
            <Text textType="t3">High Expertise High Hours</Text>

            {/* Low Expertise Low Hours */}
            <div className={classNames(c.loExLoHr, 'w-100 mt-2')}>
              <Text textType="t3" classToOverride="fw-bold">
                {LoExLoHr.length}
              </Text>
            </div>
            <Text textType="t3">Low Expertise Low Hours</Text>

            {/* Low Expertise High Hours */}
            <div className={classNames(c.loExHiHr, 'w-100 mt-2')}>
              <Text textType="t3" classToOverride="fw-bold">
                {LoExHiHr.length}
              </Text>
            </div>
            <Text textType="t3">Low Expertise High Hours</Text>

            <Text
              textType="t3"
              classToOverride={classNames(c.primaryColor, 'mt-5 fw-bold')}
            >
              Total :{' '}
              {HiExLoHr.length +
                HiExHiHr.length +
                LoExLoHr.length +
                LoExHiHr.length}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertiseJourney2;

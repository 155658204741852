/*global chrome */
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { ArrowBackIosNew } from '@mui/icons-material';
import Headings from '../../../Shared/Headings';
import classNames from 'classnames';
import courseDataStyling from '../courseData.module.scss';
import CourseContantData from './CourseContantData';
import ContantCreator from './ContantCreator';
// import { Resources } from '../../../../config/Resources';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { setIsTopicModel } from '../../../../redux/Actions/ExtensionHandler';
import { SET_TOAST_ERROR } from '../../../../redux/Actions/Common';
import { CHROME_EXTENSION_ID } from '../../../../config/Constants';
import { handleStartStop } from '../../../../helpers/ExtensionHandler';
import { setIsSessionDetailsOpen } from '../../../../redux/Actions/ExtensionHandler';
import SessionDetails from '../../../Shared/SessionDetails';
import { googleAnalyticsTriggerFn } from '../../../../utils/utility';
import { setIsExtensionBtnEnabled } from '../../../../redux/Actions/ExtensionHandler';

const CourseContent = ({
  courseDetails,
  category,
  campaignCategory,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const IsExtensionActive = useSelector((state) => state.IsExtensionActive);
  const isSessionDetailsOpen =
    useSelector((state) => state?.isSessionDetailsOpen) || false;
  const userData = useSelector((state) => state.generalInformation);
  const barData = useSelector((state) => state?.barData[0]) || [];
  const sessionInfo = useSelector((state) => state?.sessionData[0]) || [];
  const IsExtensionBtnEnabled = useSelector(
    (state) => state?.IsExtensionBtnEnabled
  );
  // useEffect(() => {
  //   if (chrome.runtime) {
  //     const tmp = chrome.runtime.connect(CHROME_EXTENSION_ID);
  //     const port = tmp;
  //     if (port) {
  //       port.onMessage.addListener((msg) => {
  //         if (msg.update_ui) {
  //           extUpdateUI();
  //         }
  //       });
  //     }
  //   }
  // }, []);
  const handleStartSession = (e, topic) => {
    e.preventDefault();
    if (chrome && chrome.runtime) {
      handleStartStop(true, topic);
    } else {
      dispatch(
        SET_TOAST_ERROR({
          error: true,
          message: 'Extension Is not Installed or Something Went Wrong',
        })
      );
      window.open(
        `https://chrome.google.com/webstore/detail/ahura-ai-study-sessions/${CHROME_EXTENSION_ID}`
      );
    }
  };
  const handleStopSession = (e) => {
    e.preventDefault();
    handleStartStop(false);
  };
  // useEffect(() => {
  //   dispatch(setIsExtensionBtnEnabled(IsExtensionActive));
  // }, [IsExtensionActive]);
  return (
    <>
      <div className={classNames('col-lg-5')}>
        <div
          className={classNames(
            'd-flex align-items-center',
            courseDataStyling.courseBack,
            courseDataStyling.cursorPointer
          )}
          onClick={() => {
            history.push('/content');
            googleAnalyticsTriggerFn(
              'Button',
              'Back',
              'Back cutton clicked on course page'
            );
          }}
        >
          <ArrowBackIosNew />
          <Headings classToOverride={classNames('ms-3 ')} headingType="h5">
            Back
          </Headings>
        </div>

        <div
          className={classNames(
            'd-flex align-items-center',
            courseDataStyling.courseBack,
            courseDataStyling.cursorPointer
          )}
        >
          <div className={classNames(courseDataStyling.StartCourseBtnDiv)}>
            {IsExtensionActive ? (
              <Button
                disabled={IsExtensionBtnEnabled}
                className={classNames(
                  'mt-5 p-3 fw-bold',
                  courseDataStyling.StartCourseBtn
                )}
                variant="contained"
                onClick={(e) => {
                  handleStopSession(e);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Stop Ahura AI',
                    'Stop Ahura AI clicked'
                  );
                }}
              >
                <Headings headingType="h5"> Stop Ahura AI</Headings>
              </Button>
            ) : (
              <Button
                disabled={IsExtensionBtnEnabled || !userData?.id}
                className={classNames(
                  'mt-5 p-3 fw-bold',
                  courseDataStyling.StartCourseBtn
                )}
                onClick={(e) => {
                  handleStartSession(e, campaignCategory);
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Start Ahura AI',
                    'Start Ahura AI clicked'
                  );
                }}
                variant="contained"
              >
                <Headings headingType="h5"> Start Ahura AI</Headings>
              </Button>
            )}
            {userData?.id ? (
              <span className={classNames(courseDataStyling.StartCourseBtnTextBelow)}>
                Click content below after starting Ahura
              </span>
            ) : (
              <span className={classNames(courseDataStyling.StartCourseBtnTextBelowDisabled)}>
                <a href="" onClick = {() => {history.push('/sign-in', { fromAhuraDomain: true })}}>Sign In</a> To Start the session
              </span>
            )}
            
          </div>
          {category === 'lesson' && (
            <div className={classNames(courseDataStyling.TakeQuizBtnDiv)}>
              <Button
              disabled={!userData?.id}
                className={classNames(
                  'mt-2 p-3 fw-bold',
                  courseDataStyling.TakeQuizBtn
                )}
                variant="contained"
                onClick={() => {
                  if (Array.isArray(courseDetails?.questions)) {
                    history.push(`/lesson-quiz/${courseDetails?.campaign_id}`);
                  } else {
                    dispatch(
                      SET_TOAST_ERROR({
                        error: true,
                        message: "Assignment doesn't contain the question.",
                      })
                    );
                  }
                  googleAnalyticsTriggerFn(
                    'Button',
                    'Take Quiz',
                    'Take Quiz button clicked'
                  );
                }}
              >
                <Headings headingType="h5">Take quiz</Headings>
              </Button>
              {userData?.id ? (
                <span className={classNames(courseDataStyling.TakeQuizBtnTextBelow)}>
                  This marks the lesson complete
                </span>
                ) : (
                  <span className={classNames(courseDataStyling.TakeQuizBtnTextBelowDisabled)}>
                    <a href="" onClick = {() => {history.push('/sign-in', { fromAhuraDomain: true })}}>Sign In</a> To Take the Quiz
                  </span>
                )
              }
            </div>
          )}
        </div>

        <CourseContantData
          CourseTitle={courseDetails?.heading}
          CourseData={courseDetails?.description}
        />

        <div>
          <ContantCreator
            CreatorName={courseDetails?.creator_name}
            Role="Content Creator"
            CreatorProfileLogo={courseDetails?.creator_profile_image_url}
          />
        </div>
      </div>
      {isSessionDetailsOpen && (
        <SessionDetails
          sessionInfo={sessionInfo}
          barInfo={barData}
          setModal={() => dispatch(setIsSessionDetailsOpen(false))}
        />
      )}
    </>
  );
};
export default CourseContent;

import axios from 'axios';
import { API_URLS } from '../../config/API_URLS/api_urls';
import store from '../../redux/store';
import { SET_TOAST_ERROR } from '../../redux/Actions/Common';
const axiosInstance = axios.create({
  baseURL: API_URLS.baseURL,
});

axiosInstance.interceptors.request.use((config) => {
  //   store.dispatch(actions.showLoader());
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if(error?.response?.data?.error?.code===1004){
      localStorage.clear();
      window.location.href='/sign-in'
    }
    
    // store.dispatch(actions.hideLoader());
    store.dispatch(
      SET_TOAST_ERROR({
        error: true,
        message: error?.response?.data?.error?.message,
      })
    );
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;

import { API_URLS } from '../../../config/API_URLS/api_urls';
import { getApiCall } from '../../../utils/axios/axios';

export const Employeecolumns = [
  { id: 'employee', label: 'Learner' },
  { id: 'active', label: 'Active' },
  {
    id: 'last3topics',
    label: 'Last 3 topics',

    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: 'timeStudied',
    label: 'Time Studied',
    // format: (value) => value.toFixed(2),
  },
  { id: 'nudges_per_x_min', label: 'Nudges/30min' },
  { id: 'struggling_emotions', label: 'Struggle Emotions' },
  {id: 'view_dashboard', label: 'View Dashboard'},
  {
    id: 'topicsStudied',
    label: 'Topics Studied',
    // format: (value) => value.toLocaleString('en-US'),
  },
  { id: 'department', label: 'Department/Class' },
  
  
];


export const getAllCorpEmployees = async (corp_id,platform_id, filter = 'this') => {
  const params = {
    corporate_id: corp_id,
    platform_id:platform_id,
    limit: 300,
    page_no: 1,
    filter: filter,
    search:""
  };
  const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
  const res = await getApiCall(API_URLS.allocation_table, paramsData);
  return res?.data;
};

export const filters = [
  {
    label: 'All time',
    value: 'all',
  },
  {
    label: 'This Month',
    value: 'this',
  },
  {
    label: 'Last Month',
    value: 'prev',
  },
];

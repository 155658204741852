export const columns = [
  { id: 'time_finished', label: 'Date' },
  { id: 'description', label: 'Topic' },
  { id: 'duration', label: 'Duration' },
  { id: 'nudges_per_x_min', label: 'Nudges/30min' },
  { id: 'struggling_emotions', label: 'Struggle Emotions' },
  { id: 'actions', label: 'Actions' }
];

export const manager_columns = [
  { id: 'time_finished', label: 'Date' },
  { id: 'description', label: 'Topic' },
  { id: 'duration', label: 'Duration' }
];

export const menuItems = [
  { label: 'Start Ahura AI', value: 'start' },
  { label: 'View Session', value: 'view' },
];

export const TableData = [
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
  {
    date: '01/01/2022',
    topic: 'Python',
    source: 'Assigned',
    duration: '6hr 30min',
    assessment: 'score',
  },
];

import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/Actions/Loader';
import {
  getUserInfo,
  updateUserDetails,
  generateInitialValues,
  UserDataValidationSchema,
} from '../../../helpers/Profile/GeneralInfo';
import { useFormik } from 'formik';
import EditAndSaveBtn from '../FormEditAndSubmit';
import classNames from 'classnames';
import c from '../Profile.Module.scss';
import Text from '../../Shared/Text';
import { Divider } from '@mui/material';
import ProfileImageUpload from './ProfileImageUpload';
import Demographics from './DemoGraphics';
import AccountDetails from './AccountDetails';
import { ROLE_IDS } from '../../../config/Constants';
import ClientAccountDetailForm from './ClientAccountDetailForm';

const GeneralInformation = () => {
  const [editable, setEditable] = useState(false);
  const userGeneralInfo =
    useSelector((state) => state?.generalInformation) || {};

  const isRoleUser = userGeneralInfo?.role_id == ROLE_IDS.USER;

  const dispatch = useDispatch();

  const onFormSubmit = async (values) => {
    dispatch(showLoader());
    const userDetails = await getUserInfo(values);
    await updateUserDetails(userDetails);
    dispatch(hideLoader());
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues({ ...userGeneralInfo }),
    validationSchema: UserDataValidationSchema,
    validateOnMount: true,
    onSubmit: (values) => onFormSubmit(values),
  });

  return (
    <>
      {!isRoleUser ? (
        <ClientAccountDetailForm />
      ) : (
        <>
          <EditAndSaveBtn
            editable={editable}
            setEditable={setEditable}
            handleSubmit={formik.handleSubmit}
            disabled={!formik.isValid}
            page="General Information"
          />

          <div className={classNames('rounded pt-3 ps-3', c.generalInfo)}>
            <Text textType="t1" classToOverride={c.fontWeight600}>
              General Information
            </Text>
            {/* <Text textType="t2" classToOverride={c.fade}>
          Another line here for description
        </Text> */}
            <Text textType="t2" classToOverride="mt-4 fw-light">
              Primary Image
            </Text>
            <Divider className="mt-2" />
            <form>
              <ProfileImageUpload isEditable={!editable} formik={formik} />
              <Demographics isEditable={!editable} formik={formik} />
              <AccountDetails isEditable={!editable} formik={formik} />
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralInformation;
